import {
    BankAccountType,
    type BankOwnerAccountStates,
    type BankTransactionCategory,
    type BankTransactionCategoryType,
    type BankTransactionSource,
    BankTransactionState,
    CalendarResponseStatus,
    CompanyType,
    ConstructionPlanEvents,
    ConstructionPlanState,
    ConstructionPlanType,
    ConstructionProgressStatus,
    ContactType,
    DefectEvents,
    DefectStatus,
    DistributionType,
    DocumentCategory,
    DocumentInspectionState,
    type EnergyConsumptionCategory,
    type EstateChannelName,
    type EstateChannelType,
    type EstateSource,
    FormStatus,
    InspectionState,
    InteriorQuality,
    InvestmentCaseState,
    InvestmentCaseType,
    InvoiceEvents,
    InvoiceStates,
    InvoiceType,
    LeadImpression,
    type LedgerEntryGroup,
    type LedgerEntryGroupType,
    type LedgerEntryType,
    type LoanAccountState,
    type LoanAccountType,
    LoanDrawdownStatus,
    LoanDrawdownType,
    MFHInvestmentCaseStatus,
    MFHProcessType,
    MFHSourcingState,
    OwnerManagementStates,
    PortfolioEvents,
    PositionEditType,
    Priority,
    ProcessType,
    PropertyManagementGroup,
    PurchasingState,
    type RenovationInspectionType,
    RenovationStates,
    RentableUnitStates,
    RentingListingState,
    RentingStates,
    ReviewProcessState,
    ReviewRequestState,
    SellingStates,
    SourcingStates,
} from '@condo/domain';
import { CustomStateColorMapper, PartialStateColorMapper, StateColorMapper, StateMachineStyle } from './typings';

export const statesColorMap = {
    [ProcessType.Sourcing]: {
        [SourcingStates.New]: 'is-warning',
        [SourcingStates.Contacted]: 'is-info',
        [SourcingStates.Duplicate]: 'is-danger',
        [SourcingStates.DataValidation]: 'is-warning',
        [SourcingStates.Unpublished]: 'is-grey',
        [SourcingStates.Processing]: 'is-link',
        [SourcingStates.ManualReview]: 'is-warning',
        [SourcingStates.NotInScope]: 'is-grey',
        [SourcingStates.Cancelled]: 'is-grey',
        [SourcingStates.Rejected]: 'is-danger',
        [SourcingStates.InspectionScheduled]: 'is-clink',
        [SourcingStates.Inspected]: 'is-clink',
        [SourcingStates.Offer]: 'is-info',
        [SourcingStates.Completed]: 'is-success',
        [SourcingStates.Prediction]: 'is-clink',
        [SourcingStates.PredictionFailed]: 'is-warning',
        [SourcingStates.ExternalDataCollection]: 'is-green-dark',
        [SourcingStates.ProfitabilityCheck]: 'is-info',
        [SourcingStates.Accepted]: 'is-success',
        [SourcingStates.Waiting]: 'is-success',
        [SourcingStates.PreApproved]: 'is-success',
        [SourcingStates.OfferMade]: 'is-success-dark',
        [SourcingStates.OfferRejected]: 'is-warning-dark',
        [SourcingStates.ExportedToPartner]: 'is-green-dark',
        [SourcingStates.ContactRequested]: 'is-warning-dark',
    },
    [ProcessType.Purchasing]: {
        [PurchasingState.OfferAccepted]: 'is-green-dark',
        [PurchasingState.DraftReceived]: 'is-link',
        [PurchasingState.DraftApproved]: 'is-info',
        [PurchasingState.Notarized]: 'is-success-dark',
        [PurchasingState.FmReceived]: 'is-info',
        [PurchasingState.Paid]: 'is-green-dark',
        [PurchasingState.Cancelled]: 'is-warning',
        [PurchasingState.Completed]: 'is-success',
    },
    [ProcessType.Renovation]: {
        [RenovationStates.Identified]: 'is-clink',
        [RenovationStates.Measured]: 'is-clink',
        [RenovationStates.Modelled]: 'is-clink',
        [RenovationStates.Briefed]: 'is-clink',
        [RenovationStates.CapturedWithContractor]: 'is-clink',
        [RenovationStates.RenovationStarted]: 'is-info',
        [RenovationStates.InRenovation]: 'is-info',
        [RenovationStates.OnHold]: 'is-info',
        [RenovationStates.InWarranty]: 'is-info',
        [RenovationStates.Completed]: 'is-success',
    },
    [ProcessType.Renting]: {
        [RentingStates.Identified]: 'is-clink',
        [RentingStates.FullyOccupiedTBV]: 'is-green-dark',
    },
    [ProcessType.RentableUnit]: {
        [RentableUnitStates.Identified]: 'is-clink',
    },
    [ProcessType.Selling]: {
        [SellingStates.Identified]: 'is-clink',
        [SellingStates.SellingPartnerMandated]: 'is-primary',
        [SellingStates.SellingPartnerPrepared]: 'is-link',
        [SellingStates.OnMarket]: 'is-clink',
        [SellingStates.OfferReceived]: 'is-grey',
        [SellingStates.OfferAccepted]: 'is-green-dark',
        [SellingStates.NotaryDraftReceived]: 'is-clink',
        [SellingStates.DraftApproved]: 'is-green-dark',
        [SellingStates.Notarized]: 'is-clink',
        [SellingStates.FMReceived]: 'is-link',
        [SellingStates.Paid]: 'is-success',
        [SellingStates.Completed]: 'is-success-dark',
    },
    [ProcessType.OwnerManagement]: {
        [OwnerManagementStates.Completed]: 'is-success',
        [OwnerManagementStates.Cancelled]: 'is-grey',
        [OwnerManagementStates.Identified]: 'is-clink',
        [OwnerManagementStates.Regular]: 'is-primary',
    },
};

export const craftStatusMap = {
    [ConstructionProgressStatus.NotStarted]: 'is-warning',
    [ConstructionProgressStatus.InProgress]: 'is-primary',
    [ConstructionProgressStatus.Done]: 'is-default',
};

export const distributionTypeColorMap = {
    [DistributionType.FFF]: 'is-green',
    [DistributionType.FRF]: 'is-clink',
};

export const rentingListingColorMap = {
    [RentingListingState.Created]: 'is-danger',
    [RentingListingState.Prepublished]: 'is-warning',
    [RentingListingState.Published]: 'is-success',
};

export const interiorQualityColorMap = {
    [InteriorQuality.Simple]: 'is-danger',
    [InteriorQuality.Normal]: 'is-clink',
    [InteriorQuality.Sophisticated]: 'is-warning',
    [InteriorQuality.Luxury]: 'is-success',
};

export const interiorQualityHexColorMap = {
    [InteriorQuality.Simple]: '#F14667',
    [InteriorQuality.Normal]: '#1E4789',
    [InteriorQuality.Sophisticated]: '#FFE089',
    [InteriorQuality.Luxury]: '#49C68D',
};

// todo: merge them with bulmaColorRGB
export const graphColors = {
    cyan: '#00d1b2',
    green: '#23d160',
    purple: '#8c67ef',
    yellow: '#ffdd57',
    red: '#FF0000',
    blue: '#3273dc',
    lightBlue: '#7cb5ec',
    orange: '#ff7300',
    lightGrey: '#adadad',
};

type BulmaColors = 'clink' | 'success' | 'darkSuccess' | 'green' | 'darkGreen' | 'blue' | 'link' | 'purple' | 'red' | 'orange' | 'darkOrange' | 'default';

export const bulmaColorTypes: { [key in BulmaColors]: string } = {
    clink: 'is-clink',
    green: 'is-green',
    darkGreen: 'is-green-dark',
    success: 'is-success',
    darkSuccess: 'is-success-dark',
    blue: 'is-info',
    link: 'is-link',
    purple: 'is-primary',
    red: 'is-danger',
    orange: 'is-warning',
    darkOrange: 'is-warning-dark',
    default: 'is-light',
};

export const bulmaColorRGB: { [key in BulmaColors]: string } = {
    clink: '#174287',
    green: '#00d1b2',
    darkGreen: '#00806C',
    success: '#23d160',
    darkSuccess: '#35AC5E',
    blue: '#209cee',
    link: '#3273dc',
    purple: '#7957d5',
    red: '#ff3860',
    orange: '#ffdd57',
    darkOrange: '#CBA406',
    default: '#f5f5f5',
};

export const colors = {
    getType(color: BulmaColors = 'default'): string {
        return bulmaColorTypes[color];
    },

    getRGB(color: BulmaColors = 'default'): string {
        return bulmaColorRGB[color];
    },
};

export const processMap = {
    [ProcessType.Sourcing]: {
        name: ProcessType.Sourcing,
        colorName: 'link',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'search-location',
    },
    [ProcessType.Purchasing]: {
        name: ProcessType.Purchasing,
        colorName: 'darkOrange',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'hand-holding-usd',
    },
    ['SubsidyFinancing']: {
        name: 'SubsidyFinancing',
        colorName: 'purple',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'hand-holding-usd',
    },
    ['Financing']: {
        name: 'Financing',
        colorName: 'purple',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'hand-holding-usd',
    },
    [ProcessType.Renovation]: {
        name: ProcessType.Renovation,
        colorName: 'darkSuccess',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'tools',
    },
    [ProcessType.Renting]: {
        name: ProcessType.Renting,
        colorName: 'darkGreen',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'door-open',
    },
    [ProcessType.RentableUnit]: {
        name: ProcessType.RentableUnit,
        colorName: 'darkGreen',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'bed-front',
    },
    [ProcessType.Selling]: {
        name: ProcessType.Selling,
        colorName: 'clink',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'comments-dollar',
    },
    [ProcessType.PropertyManagement]: {
        name: 'Managing',
        colorName: 'green',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'house',
    },
    [ProcessType.TenantManagement]: {
        name: ProcessType.TenantManagement,
        colorName: 'green',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'user-gear',
    },
    [ProcessType.OwnerManagement]: {
        name: ProcessType.OwnerManagement,
        colorName: 'green',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'user-cowboy',
    },
    [ProcessType.EstateManagement]: {
        name: ProcessType.EstateManagement,
        colorName: 'green',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'house-person-return',
    },
};

export const mfhProcessMap = {
    [MFHProcessType.Sourcing]: {
        name: ProcessType.Sourcing,
        colorName: 'link',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'search-location',
    },
    [MFHProcessType.Purchasing]: {
        name: ProcessType.Purchasing,
        colorName: 'darkOrange',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'hand-holding-usd',
    },
    [MFHProcessType.SubsidyFinancing]: {
        name: 'SubsidyFinancing',
        colorName: 'purple',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'hand-holding-usd',
    },
    [MFHProcessType.EstateManagement]: {
        name: ProcessType.EstateManagement,
        colorName: 'green',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'house-person-return',
    },
    [MFHProcessType.OwnerManagement]: {
        name: ProcessType.OwnerManagement,
        colorName: 'green',
        get type() {
            return bulmaColorTypes[this.colorName];
        },
        get color() {
            return bulmaColorRGB[this.colorName];
        },
        icon: 'user-cowboy',
    },
};

export const constructionStateColorMapper = {
    [ConstructionPlanState.Draft]: 'is-grey',
    [ConstructionPlanState.Review]: 'is-clink',
    [ConstructionPlanState.Approved]: 'is-success-dark',
    [ConstructionPlanState.Rejected]: 'is-danger',
};

export const constructionPlanTypesColorMapper = {
    [ConstructionPlanType.General]: 'is-link',
    [ConstructionPlanType.Cleaning]: 'is-info',
    [ConstructionPlanType.Kitchen]: 'is-clink',
    [ConstructionPlanType.Electricity]: 'is-grey',
};

export const drawdownStatusColor = {
    [LoanDrawdownStatus.Draft]: 'is-grey',
    [LoanDrawdownStatus.Requested]: 'is-link',
    [LoanDrawdownStatus.Received]: 'is-success-dark',
    [LoanDrawdownStatus.Rejected]: 'is-danger',
    [LoanDrawdownStatus.Cancelled]: 'is-grey-light',
};

export const investmentCaseColors = {
    [InvestmentCaseState.Approved]: 'is-success-dark',
    [InvestmentCaseState.Executed]: 'is-green-dark',
    [InvestmentCaseState.New]: 'is-link',
    [InvestmentCaseState.Rejected]: 'is-danger',
    [InvestmentCaseState.Submitted]: 'is-clink',
    [InvestmentCaseState.Reverted]: 'is-warning-dark',
    [InvestmentCaseState.Review]: 'is-clink',
};

export const investmentCaseTypeColors = {
    [InvestmentCaseType.Purchasing]: 'is-link',
    [InvestmentCaseType.Selling]: 'is-success-dark',
    [InvestmentCaseType.ICT]: 'is-clink',
};

export const defectStatusColor = {
    [DefectStatus.ToDo]: 'is-clink',
    [DefectStatus.InProgress]: 'is-clink',
    [DefectStatus.ContractorContacted]: 'is-clink',
    [DefectStatus.AppointmentScheduled]: 'is-clink',
    [DefectStatus.FixVerified]: 'is-clink',
    [DefectStatus.Completed]: 'is-success-dark',
    [DefectStatus.Rejected]: 'is-danger',
};

export const invoiceStateColors: StateColorMapper<InvoiceStates> = {
    [InvoiceStates.Authorized]: 'is-success-dark',
    [InvoiceStates.DrawdownCompleted]: 'is-green-dark',
    [InvoiceStates.InDispute]: 'is-warning-dark',
    [InvoiceStates.ManuallyPaid]: 'is-clink',
    [InvoiceStates.ReadyForBooking]: 'is-green-dark',
    [InvoiceStates.FailedToExport]: 'is-danger',
    [InvoiceStates.ReadyForDrawdown]: 'is-green-dark',
    [InvoiceStates.New]: 'is-clink',
    [InvoiceStates.Assigned]: 'is-link',
    [InvoiceStates.Approved]: 'is-warning',
    [InvoiceStates.WaitingForAuthorization]: 'is-warning',
    [InvoiceStates.ManualReview]: 'is-warning',
    [InvoiceStates.ReadyForPayment]: 'is-link',
    [InvoiceStates.PartiallyPaid]: 'is-link',
    [InvoiceStates.Reimbursed]: 'is-link',
    [InvoiceStates.Paid]: 'is-success',
    [InvoiceStates.Rejected]: 'is-danger',
    [InvoiceStates.Duplicated]: 'is-grey',
    [InvoiceStates.WaitingForDrawdown]: 'is-grey',
    [InvoiceStates.WaitingForExternalReview]: 'is-link',
};

export const invoiceTypeColors: StateColorMapper<InvoiceType> = {
    [InvoiceType.Normal]: 'is-clink',
    [InvoiceType.CreditNote]: 'is-warning',
    [InvoiceType.PaymentReminder]: 'is-warning-dark',
    [InvoiceType.MasterTemplate]: 'is-grey',
    [InvoiceType.RenovationGuarantee]: 'is-green-dark',
    [InvoiceType.Other]: 'is-grey',
    [InvoiceType.RecurrentPayment]: 'is-primary',
};

export const defectActionStyle: StateMachineStyle<DefectEvents> = {
    [DefectEvents.DefectMarkedAsValid]: {
        icon: 'history',
        type: bulmaColorTypes.green,
    },
    [DefectEvents.DefectMarkedAsInvalid]: {
        icon: 'history',
        type: bulmaColorTypes.red,
    },
    [DefectEvents.DefectProgressStarted]: {
        icon: 'history',
        type: bulmaColorTypes.clink,
    },
    [DefectEvents.DefectFixed]: {
        icon: 'check-circle',
        type: bulmaColorTypes.darkGreen,
    },
    [DefectEvents.DefectReopened]: {
        icon: 'times-circle',
        type: bulmaColorTypes.orange,
    },
    [DefectEvents.DefectNeedsSubstitution]: {
        icon: 'times-circle',
        type: bulmaColorTypes.orange,
    },
    default: {
        icon: 'lightbulb',
        type: bulmaColorTypes.link,
    },
};
export const constructionPlanActionStyle: StateMachineStyle<ConstructionPlanEvents> = {
    [ConstructionPlanEvents.SetToConstructionPlanDraft]: {
        icon: 'history',
        type: bulmaColorTypes.darkOrange,
    },
    [ConstructionPlanEvents.ApproveConstructionPlan]: {
        icon: 'history',
        type: bulmaColorTypes.darkOrange,
    },
    [ConstructionPlanEvents.UnapproveConstructionPlan]: {
        icon: 'history',
        type: bulmaColorTypes.darkOrange,
    },
    [ConstructionPlanEvents.CompleteConstructionPlan]: {
        icon: 'check-circle',
        type: 'is-success',
    },
    [ConstructionPlanEvents.RejectConstructionPlan]: {
        icon: 'times-circle',
        type: 'is-danger',
    },
    default: {
        type: bulmaColorTypes.link,
        icon: 'lightbulb',
    },
};

export type PortfolioActionStyle = StateMachineStyle<PortfolioEvents>;

export const portfolioActionStyle: PortfolioActionStyle = {
    [PortfolioEvents.Revert]: {
        icon: 'history',
        type: bulmaColorTypes.darkOrange,
    },
    default: {
        type: bulmaColorTypes.link,
        icon: 'lightbulb',
    },
};

export const invoiceActionsStyle: StateMachineStyle<InvoiceEvents> = {
    [InvoiceEvents.Revert]: {
        icon: 'undo',
        type: 'is-warning-dark',
    },
    [InvoiceEvents.Reject]: {
        icon: 'times-circle',
        type: 'is-danger',
    },
    [InvoiceEvents.ReadyForPayment]: {
        icon: 'euro-sign',
        type: 'is-success-dark',
    },
    [InvoiceEvents.Paid]: {
        icon: 'euro-sign',
        type: 'is-success-dark',
    },
    [InvoiceEvents.Approve]: {
        icon: 'check-circle',
        type: 'is-success',
    },
    [InvoiceEvents.Authorize]: {
        icon: 'check-circle',
        type: 'is-success',
    },
    [InvoiceEvents.ManualReview]: {
        icon: 'times-circle',
        type: 'is-danger',
    },
    [InvoiceEvents.Assign]: {
        icon: 'user-check',
        type: 'is-green-dark',
    },
    [InvoiceEvents.PrepareForBooking]: {
        icon: 'book',
        type: 'is-link',
    },
    [InvoiceEvents.PaymentOrderAdded]: {
        icon: 'file-invoice-dollar',
        type: 'is-clink',
    },
    [InvoiceEvents.Reimbursed]: {
        icon: 'money-check-alt',
        type: 'is-clink',
    },
    [InvoiceEvents.Reset]: {
        icon: 'fa fa-refresh',
        type: 'is-warning',
    },

    default: {
        icon: '',
        type: 'is-info',
    },
};

export const rentingLeadImpression: StateColorMapper<LeadImpression> = {
    [LeadImpression.Good]: 'is-green-dark',
    [LeadImpression.Neutral]: 'is-clink',
    [LeadImpression.Bad]: 'is-warning-dark',
};

export const bankCategoryColorMap: StateColorMapper<BankTransactionCategory> = {
    Unknown: 'is-grey',
    Booking: undefined,
    DirectDebit: undefined,
    Fee: 'is-warning-dark',
    Operational: 'is-grey',
    PropertyManagement: 'is-info',
    Revenue: 'is-success-dark',
    ServiceCharges: 'is-clink',
    Storno: 'is-grey',
    Transfer: 'is-link',
    Drawdown: 'is-clink',
};

export const bankCategoryTypeColorMap: PartialStateColorMapper<BankTransactionCategoryType> = {
    Unknown: 'is-grey',
    Combined: 'is-primary',
    Commission: 'is-link',
    Commitment: 'is-info',
    HouseGeld: 'is-success',
    Interest: 'is-warning',
    ReturnDebit: 'is-warning',
    ReturnDebitFee: 'is-warning',
    Intergroup: 'is-danger',
    InvestorIncome: 'is-primary',
    Invoice: 'is-link',
    JuniorDebt: 'is-info',
    Other: 'is-green-dark',
    OwnFunds: 'is-warning-dark',
    Processing: 'is-danger',
    PurchasingPrice: 'is-primary',
    Rent: 'is-clink',
    PropertyTax: 'is-success-dark',
    ManagementFee: 'is-info',
    Selling: 'is-success-dark',
    Storno: 'is-danger',
};

export const propertyManagementSubPage = {
    [PropertyManagementGroup.Estate]: {
        type: 'is-info',
        icon: 'building',
    },
    [PropertyManagementGroup.Tenant]: {
        type: 'is-success',
        icon: 'user',
    },
    [PropertyManagementGroup.SEV]: {
        type: 'is-light',
        icon: 'door-open',
    },
};
export const bankTransactionStateMap: StateColorMapper<BankTransactionState> = {
    Error: 'is-warning-dark',
    Reconciled: 'is-green-dark',
    [BankTransactionState.Pending]: 'is-grey',
    [BankTransactionState.Storno]: 'is-grey',
    [BankTransactionState.Incomplete]: 'is-warning',
};

export const bankTransactionSourceColorMap: StateColorMapper<BankTransactionSource> = {
    System: 'is-clink',
    User: 'is-light',
};

export const ledgerEntryGroupColors: StateColorMapper<LedgerEntryGroup> = {
    Tranche: undefined,
    Booking: 'is-grey',
    Drawdown: 'is-clink',
    Fee: 'is-warning-dark',
    Operation: 'is-link',
    PropertyManagement: 'is-success-dark',
    Repayment: 'is-info',
    Revenue: 'is-green-dark',
    Storno: 'is-grey',
    Transfer: 'is-clink',
    Asset: 'is-clink',
    Liability: 'is-clink',
    Expense: 'is-clink',
    Equity: 'is-clink',
};

export const ledgerEntryTypeColor: StateColorMapper<LedgerEntryType> = {
    Accrual: 'is-grey',
    Cash: 'is-light',
};

export const entryGroupTypeColor: StateColorMapper<LedgerEntryGroupType> = {
    AncillaryCosts: undefined,
    BankFee: undefined,
    BankFeeCompensation: undefined,
    ColdRent: undefined,
    HOAFee: undefined,
    HOAFeeSubsidy: undefined,
    Heating: undefined,
    HouseGeldDealPayback: undefined,
    InvestorIncome: undefined,
    LiquidityReserve: undefined,
    LoanAmortization: undefined,
    ManagementFee: undefined,
    OtherCost: undefined,
    Parking: undefined,
    PropertyTax: undefined,
    Rent: undefined,
    RentArrearFee: undefined,
    RentGuarantee: undefined,
    RentReduction: undefined,
    ReturnDebit: undefined,
    ReturnDebitFee: undefined,
    SettlementResult: undefined,
    Utilities: undefined,
    Tranche: undefined,
    OwnFunds: undefined,
    Combined: undefined,
    Commission: undefined,
    Commitment: 'is-info',
    Correction: undefined,
    Interest: 'is-clink',
    Intergroup: undefined,
    Invoice: undefined,
    JuniorDebt: undefined,
    Other: undefined,
    Principal: undefined,
    Processing: undefined,
    PurchasingPrice: undefined,
    Selling: undefined,
    ServiceCharge: undefined,
    Storno: undefined,
};

export const bankAccountTypeColor: {
    Other: string;
    Business: string;
    Loan: string;
    Tenant: string;
    [BankAccountType.BankServiceAccount]: string;
    [BankAccountType.Investor]: string;
    [BankAccountType.RentDeposit]: string;
    [BankAccountType.TenantOwned]: string;
} = {
    Other: 'is-grey',
    Business: 'is-green-dark',
    Loan: 'is-info',
    Tenant: 'is-link',
    [BankAccountType.BankServiceAccount]: 'is-green-dark',
    [BankAccountType.Investor]: 'is-link',
    [BankAccountType.RentDeposit]: 'is-link',
    [BankAccountType.TenantOwned]: 'is-link',
};

export const bankOwnerAccountStateColor: StateColorMapper<BankOwnerAccountStates> = {
    Created: 'is-grey',
    ReadyForValidation: 'is-clink',
    PublicKeysSent: 'is-warning',
    ManualDocumentValidation: 'is-warning-dark',
    Validated: 'is-green',
};

export const loanAccountStateColor: StateColorMapper<LoanAccountState> = {
    Closed: 'is-green-dark',
    Open: 'is-clink',
};

export const loanAccountTypeColor: StateColorMapper<LoanAccountType> = {
    IntergroupLoan: 'is-light',
    Mezzanine: 'is-clink',
    PortfolioLoan: 'is-link',
};

export const drawdownTypeColor: StateColorMapper<LoanDrawdownType> = {
    Intergroup: 'is-link',
    JuniorDebt: 'is-clink',
    [LoanDrawdownType.PurchasingPrice]: 'is-green-dark',
    [LoanDrawdownType.Invoices]: 'is-link',
};

export const estateSourceColors: PartialStateColorMapper<EstateSource> = {
    COCKPIT: 'is-light',
    DISCOVERER: 'is-grey',
    PAPI: 'is-link',
    IMPORT: 'is-info',
};

export const estateChannelTypeColors: StateColorMapper<EstateChannelType> = {
    AGENT: 'is-light',
    DIRECT: 'is-grey',
    PLATFORM: 'is-info',
};

export const estateChannelNameColors: StateColorMapper<EstateChannelName> = {
    GEOMAP: 'is-clink',
    IMMO24: 'is-green-dark',
    PRICEHUBBLE: 'is-info',
    TRANSACTION_TEAM: 'is-grey',
    GPT: 'is-clink',
};

export const constructionPositionEditTypeIcon: Record<PositionEditType, string> = {
    [PositionEditType.Add]: 'plus',
    [PositionEditType.Update]: 'pencil-alt',
    [PositionEditType.Delete]: 'trash-alt',
};

export const constructionPositionEditTypeColor: StateColorMapper<PositionEditType> = {
    [PositionEditType.Add]: 'green-dark',
    [PositionEditType.Update]: 'warning-dark',
    [PositionEditType.Delete]: 'grey',
};

export const inspectionTypeColors: StateColorMapper<RenovationInspectionType> = {
    BasicInstallationCheck: 'is-clink',
    ExternalAcceptance: 'is-success-dark',
    General: 'is-grey',
    GroundLevelingCheck: 'is-primary',
    InternalAcceptance: 'is-green-dark',
    PlanningVerification: 'is-clink',
    RenovationStart: 'is-grey',
    SealingCheck: 'is-clink',
};

export const companyTypeColors: StateColorMapper<CompanyType> = {
    [CompanyType.Bank]: 'is-green',
    [CompanyType.Realtor]: 'is-primary',
    [CompanyType.DistributionPartner]: 'is-green-dark',
    [CompanyType.Notary]: 'is-clink',
    [CompanyType.PurchasingEntity]: 'is-success',
    [CompanyType.General]: 'is-grey',
    [CompanyType.KitchenSupplier]: 'is-info',
    [CompanyType.MeasurementsProvider]: 'is-info',
    [CompanyType.ContractorGeneral]: 'is-info',
    [CompanyType.ContractorCleaning]: 'is-info',
    [CompanyType.ContractorElectricity]: 'is-info',
    [CompanyType.GeneralPropertyManagement]: 'is-success-dark',
    [CompanyType.IndividualPropertyManager]: 'is-warning-dark',
    [CompanyType.DistrictCourt]: 'is-info',
    [CompanyType.ConstructionAuthority]: 'is-info',
    [CompanyType.WaterProvider]: 'is-info',
    [CompanyType.ElectricityProvider]: 'is-info',
    [CompanyType.GasProvider]: 'is-info',
    [CompanyType.FacilityManagement]: 'is-info',
    [CompanyType.ServiceProvider]: 'is-info',
    [CompanyType.SellingNotary]: 'is-clink',
    [CompanyType.MaterialSupplier]: 'is-info',
    [CompanyType.TaxOffice]: 'is-info',
    [CompanyType.AssetManagement]: 'is-info',
    [CompanyType.HrCompany]: 'is-info',
    [CompanyType.OfficeSupplier]: 'is-info',
    [CompanyType.TravelProvider]: 'is-info',
    [CompanyType.RentalCarCompany]: 'is-info',
    [CompanyType.MarketingCompany]: 'is-info',
    [CompanyType.HardwareProvider]: 'is-info',
    [CompanyType.SoftwareCompany]: 'is-info',
    [CompanyType.Seller]: 'is-info',
    [CompanyType.Buyer]: 'is-info',
    [CompanyType.AssetPartner]: 'is-clink',
};

/*  Current Color Mapping

    Class                   Bg        Fg
    =====================   =======   =======
    is-white                #FFFFFF   #0a0a0a
    is-black                #0a0a0a   #FFFFFF
    is-light                #f5f5f5   #000000b3
    is-dark                 #363636   #FFFFFF
    is-primary              #8c67ef   #FFFFFF
    is-info                 #3e8ed0   #FFFFFF
    is-success              #48c78e   #FFFFFF
    is-warning              #ffe08a   #000000b3
    is-danger               #f14669   #FFFFFF
    is-green-dark           #00806c   #FFFFFF
    is-success-dark         #35ac5e   #FFFFFF
    is-clink                #174287   #FFFFFF
    is-warning-dark         #cba406   #FFFFFF
    is-grey                 #b5b5b5   #ffffff
 */

export const renovationStatusColors: CustomStateColorMapper<RenovationStates> = {
    [RenovationStates.Identified]: {
        backgroundColor: '#969696 !important',
        color: 'white !important',
    },
    [RenovationStates.Measured]: {
        backgroundColor: '#d9d9d9 !important',
        color: 'black !important',
    },
    [RenovationStates.Modelled]: {
        backgroundColor: '#79addc !important',
        color: 'white !important',
    },
    [RenovationStates.Planned]: {
        backgroundColor: '#44cb93 !important',
        color: 'white !important',
    },
    [RenovationStates.Briefed]: {
        backgroundColor: '#0b1d51 !important',
        color: 'white !important',
    },
    [RenovationStates.Prepared]: {
        backgroundColor: '#008000 !important',
        color: 'white !important',
    },
    [RenovationStates.CapturedWithContractor]: {
        backgroundColor: '#00a7e1 !important',
        color: 'white !important',
    },
    [RenovationStates.RenovationStarted]: {
        backgroundColor: '#ff0000 !important',
        color: 'white !important',
    },
    [RenovationStates.InRenovation]: {
        backgroundColor: '#00388c !important',
        color: 'white !important',
    },
    [RenovationStates.OnHold]: {
        backgroundColor: '#2d0605 !important',
        color: 'white !important',
    },
    [RenovationStates.DefectsPending]: {
        backgroundColor: '#e55812 !important',
        color: 'white !important',
    },
    [RenovationStates.InWarranty]: {
        backgroundColor: '#ffe45e !important',
        color: 'black !important',
    },
    [RenovationStates.CompletionRequested]: {
        backgroundColor: '#1c7c54 !important',
        color: 'white !important',
    },
    [RenovationStates.Completed]: {
        backgroundColor: '#1c7c54 !important',
        color: 'white !important',
    },
    [RenovationStates.Cancelled]: {
        backgroundColor: '#1c7c54 !important',
        color: 'white !important',
    },
};

export const mfhInvestmentCaseStatusColors = {
    [MFHInvestmentCaseStatus.Creating]: '!bg-gray-200',
    [MFHInvestmentCaseStatus.Created]: 'is-link',
    [MFHInvestmentCaseStatus.Review]: 'is-clink',
    [MFHInvestmentCaseStatus.Approved]: 'is-success-dark',
    [MFHInvestmentCaseStatus.Rejected]: 'is-danger',
    [MFHInvestmentCaseStatus.Reverted]: 'is-warning-dark',
    [MFHInvestmentCaseStatus.Error]: 'is-danger !bg-red-800',
};

export const priorityColors: StateColorMapper<Priority> = {
    [Priority.Low]: 'is-grey',
    [Priority.Normal]: 'is-clink',
    [Priority.High]: 'is-warning',
};

export const defectStatusColors: StateColorMapper<DefectStatus> = {
    [DefectStatus.ToDo]: 'is-info',
    [DefectStatus.InProgress]: 'is-clink',
    [DefectStatus.Completed]: 'is-success',
    [DefectStatus.Rejected]: 'is-danger',
    [DefectStatus.ContractorContacted]: 'is-clink',
    [DefectStatus.AppointmentScheduled]: 'is-clink',
    [DefectStatus.FixVerified]: 'is-success',
};

export const contactTypesColors: PartialStateColorMapper<ContactType> = {
    [ContactType.User]: 'is-black',
    [ContactType.PurchasingEntityDirector]: 'is-info',
    [ContactType.Seller]: 'is-success-dark',
    [ContactType.Buyer]: 'is-green-dark',
    [ContactType.RealEstateAgent]: 'is-info',
    [ContactType.Banker]: 'is-info',
    [ContactType.Notary]: 'is-warning-dark',
    [ContactType.GeneralContractor]: 'is-info',
    [ContactType.KitchenSupplier]: 'is-info',
    [ContactType.MeasurementProvider]: 'is-info',
    [ContactType.WEGVerwaltung]: 'is-info',
    [ContactType.IndividualPropertyManagement]: 'is-info',
    [ContactType.Tenant]: 'is-primary',
    [ContactType.RentingLead]: 'is-info',
    [ContactType.Generic]: 'is-info',
    [ContactType.Neighbor]: 'is-info',
    [ContactType.Craftsman]: 'is-info',
    [ContactType.SellingNotary]: 'is-warning-dark',
    [ContactType.SalesRepresentative]: 'is-info',
    [ContactType.MaterialSupplier]: 'is-info',
};

export const mfhSourcingStateColors: PartialStateColorMapper<MFHSourcingState> = {
    [MFHSourcingState.NotInScope]: 'is-grey',
    [MFHSourcingState.Validation]: 'is-warning',
    [MFHSourcingState.Identified]: 'is-clink',
    [MFHSourcingState.InReview]: 'is-info',
    [MFHSourcingState.DataCollection]: 'is-info',
    [MFHSourcingState.New]: 'is-grey',
    [MFHSourcingState.Duplicate]: 'is-warning-dark',
    [MFHSourcingState.Rejected]: 'is-danger',
    [MFHSourcingState.PreApproved]: 'is-success',
    [MFHSourcingState.FinalRejected]: 'is-danger',
    [MFHSourcingState.Approved]: 'is-success-dark',
    [MFHSourcingState.Completed]: 'is-success-dark',
    [MFHSourcingState.Unpublished]: 'is-grey',
};

export const documentColorMap: PartialStateColorMapper<DocumentCategory> = {
    General: 'is-grey',
    Sourcing: 'is-info',
    Purchasing: 'is-success',
    Renovation: 'is-clink',
    Marketing: 'is-link',
    [DocumentCategory.PortfolioManagement]: 'is-green-dark',
};

export const calendarDecisionColors: PartialStateColorMapper<CalendarResponseStatus> = {
    [CalendarResponseStatus.Accepted]: 'green-dark',
    [CalendarResponseStatus.NeedsAction]: 'grey',
    [CalendarResponseStatus.Declined]: 'danger',
    [CalendarResponseStatus.Tentative]: 'grey',
};

export const calendarDecisionIcons = {
    [CalendarResponseStatus.Accepted]: 'check-circle',
    [CalendarResponseStatus.NeedsAction]: 'question',
    [CalendarResponseStatus.Declined]: 'times',
    [CalendarResponseStatus.Tentative]: 'question',
};

export const reviewRequestColorMap: PartialStateColorMapper<ReviewRequestState> = {
    [ReviewRequestState.Requested]: 'is-clink',
    [ReviewRequestState.Approved]: 'is-green-dark',
    [ReviewRequestState.Rejected]: 'is-danger',
    [ReviewRequestState.Cancelled]: 'is-grey',
};

export const reviewProcessColorMap: PartialStateColorMapper<ReviewProcessState> = {
    [ReviewProcessState.Requested]: 'is-clink',
    [ReviewProcessState.Rejected]: 'is-danger',
    [ReviewProcessState.Approved]: 'is-green-dark',
    [ReviewProcessState.Cancelled]: 'is-grey',
};

export const reviewProcessTypeColorMap = {
    InvestmentCase: 'is-clink',
    PurchasingInvestmentCase: 'is-clink',
    SellingInvestmentCase: 'is-clink',
    Portfolio: 'is-green-dark',
    ConstructionPlan: 'is-link',
};

export const estatePurchasingInspectionStateColors: StateColorMapper<InspectionState> = {
    [InspectionState.Cancelled]: 'is-danger',
    [InspectionState.Draft]: 'is-warning',
    [InspectionState.Completed]: 'is-success-dark',
};

export const documentInspectionStateColors: StateColorMapper<DocumentInspectionState> = {
    [DocumentInspectionState.Open]: 'is-danger',
    [DocumentInspectionState.InProgress]: 'is-warning',
    [DocumentInspectionState.Completed]: 'is-success-dark',
};

export const getTimeAtRiskBackgroundColor = (numberOfMonths = 0) => {
    if (numberOfMonths > 7) {
        return '#ff0000';
    }
    if (numberOfMonths > 5) {
        return '#ff5f00';
    }
    if (numberOfMonths > 3) {
        return 'yellow';
    }

    return 'none';
};

export const getTimeOnBooksBackgroundColor = (numberOfMonths = 0) => {
    if (numberOfMonths > 9) {
        return '#ff0000';
    }
    if (numberOfMonths > 7) {
        return '#ff5f00';
    }
    if (numberOfMonths > 5) {
        return 'yellow';
    }
    return 'none';
};

export const formStatusColorMap: PartialStateColorMapper<FormStatus> = {
    [FormStatus.Draft]: 'is-warning',
    [FormStatus.Completed]: 'is-green',
};
export const distributionPartnerColorMap: CustomStateColorMapper<string> = {
    'Swiss Life AG': {
        color: '#101114FF',
        backgroundColor: '#94EBF5FF',
    },
    'CONDO Invest': {
        color: '#101114FF',
        backgroundColor: '#71B3BBFF',
    },
    'GET:FINEO GmbH & Co. KG': {
        color: '#FFFFFFFF',
        backgroundColor: '#38666BFF',
    },
    'Hamburger Liegenschaftsgesellschaft mbH': {
        color: '#FFFFFFFF',
        backgroundColor: '#11517CFF',
    },
    'LinkNow GmbH': {
        color: '#FFFFFFFF',
        backgroundColor: '#587C11FF',
    },
    'VB Select AG': {
        color: '#000000FF',
        backgroundColor: '#AAD05EFF',
    },
    Tecis: {
        color: '#000000FF',
        backgroundColor: '#E8CA31FF',
    },
    // free to take colors, these down are only existing on DEV
    PlanetHome: {
        color: '#FFFFFFFF',
        backgroundColor: '#99841DFF',
    },
    'Neuer toller Selling Partner': {
        color: '#000000FF',
        backgroundColor: '#C5C5C5FF',
    },
    'Cool Swiss': {
        color: '#FFFFFFFF',
        backgroundColor: '#6F4E92FF',
    },
};

export const energyEfficiencyClassColorMap: CustomStateColorMapper<EnergyConsumptionCategory> = {
    'A+': {
        color: '#FFFFFFFF',
        backgroundColor: '#098a3b',
    },
    A: {
        color: '#FFFFFFFF',
        backgroundColor: '#73af33',
    },
    B: {
        color: '#FFFFFFFF',
        backgroundColor: '#c9d104',
    },
    C: {
        color: '#FFFFFFFF',
        backgroundColor: '#fdec08',
    },
    D: {
        color: '#FFFFFFFF',
        backgroundColor: '#fbc206',
    },
    E: {
        color: '#FFFFFFFF',
        backgroundColor: '#ec7424',
    },
    F: {
        color: '#FFFFFFFF',
        backgroundColor: '#d0212f',
    },
    G: {
        color: '#FFFFFFFF',
        backgroundColor: '#d0212f',
    },
    H: {
        color: '#FFFFFFFF',
        backgroundColor: '#d0212f',
    },
};

export const stringToHSLColor = inputString => {
    let hash = 0;
    for (let i = 0; i < inputString.length; i++) {
        hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash; // Force the hash to be a 32-bit integer
    }

    // Calculate the hue (0-360) - full range of hues
    const hue = Math.abs(hash % 360);

    // Adjust saturation and lightness to generate aesthetically pleasing colors
    const saturation = 60 + (Math.abs(hash * 7) % 30);
    const lightness = 40 + (Math.abs(hash * 13) % 20);

    // Return the HSL color string
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const contactTailwindTypesColors: PartialStateColorMapper<ContactType> = {
    [ContactType.User]: 'bg-black',
    [ContactType.PurchasingEntityDirector]: 'bg-blue-500',
    [ContactType.Seller]: 'bg-green-800',
    [ContactType.Buyer]: 'bg-green-700',
    [ContactType.RealEstateAgent]: 'bg-blue-500',
    [ContactType.Banker]: 'bg-blue-500',
    [ContactType.Notary]: 'bg-yellow-800',
    [ContactType.GeneralContractor]: 'bg-blue-500',
    [ContactType.KitchenSupplier]: 'bg-blue-500',
    [ContactType.MeasurementProvider]: 'bg-blue-500',
    [ContactType.WEGVerwaltung]: 'bg-blue-500',
    [ContactType.IndividualPropertyManagement]: 'bg-blue-500',
    [ContactType.Tenant]: 'bg-blue-600',
    [ContactType.RentingLead]: 'bg-blue-500',
    [ContactType.Generic]: 'bg-blue-500',
    [ContactType.Neighbor]: 'bg-blue-500',
    [ContactType.Craftsman]: 'bg-blue-500',
    [ContactType.SellingNotary]: 'bg-yellow-800',
    [ContactType.SalesRepresentative]: 'bg-blue-500',
    [ContactType.MaterialSupplier]: 'bg-blue-500',
};
