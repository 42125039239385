import { statesColorMap } from '@/shared/color-mappers';
import {
    eventsAlternativeNames,
    invoiceEventAlternativeNames,
    mfhBuildingEventsAlternativeNames,
    mfhEventsAlternativeNames,
    mfhPortfolioEventsAlternativeNames,
} from '@/shared/translations';
import {
    type IAttendee,
    type IContactPerson,
    type ILocation,
    InteriorQuality,
    MediaFileCategory,
    MediaFileCategoryTranslations,
    RenovationStates,
    RentingListingProvider,
    documentTranslationsMap,
    getContactDisplayName,
} from '@condo/domain';
import { currency, intlDateFormat, percent, toTitleCase } from '@condo/formatters';
import { get, uniq } from 'lodash-es';

const documentTranslations = documentTranslationsMap;

export const toFixed = (value: number, precision = 2) => +value.toFixed(precision);

// custom one place used function to convert 100 000 into 100k and 1000 0000 into 1m
// feel free to improve, but as for now it serves its purpose
export const priceSliderFormatter = (val: number) => (val / 1000 < 1000 ? `${val / 1000}k` : `${val / 1000000}m`);

export const intlTimeFormat = (val: string | number | Date | undefined): string => {
    if (!val) {
        return '';
    }
    const options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };
    if (typeof val === 'string' || typeof val === 'number') {
        val = new Date(val);
    }
    return new Intl.DateTimeFormat('de-DE', options).format(val);
};

export const intlDate = (date, withTime = false) => intlDateFormat(date, withTime);

export const unique = (arr: []) => uniq(arr);

export const abbrToFull = (value: string | null | undefined) => {
    switch (value) {
        case RentingListingProvider.IS24:
            return 'Immoscout24';
        default:
            return value;
    }
};

export const range = (range: string[], delemiter = ' ⎯⎯⎯ ') => range.join(delemiter);

export const toHumanReadable = (value: string, dictionary: string[][]): string => {
    if (!value || !dictionary) {
        return '';
    }
    return dictionary.find(el => el[1] === value)?.[0] || value;
};

export const ternary = (value: boolean | null | undefined) => {
    switch (value) {
        case true:
            return 'Yes';
        case false:
            return 'No';
        default:
            return 'N/A';
    }
};

export const nullable = (value: any) => value ?? 'N/A';

export const pluralEndings = (val: string, one: string, many: string, zero: string, two: string, few: string): string => {
    if (!val) {
        return zero || 'no';
    }
    const pr = new Intl.PluralRules().select(+val);
    let message: string;
    switch (true) {
        case pr === 'one':
            message = one;
            break;
        case pr === 'many' || pr === 'other':
            message = many;
            break;
        case pr === 'zero':
            message = zero || 'no';
            break;
        case pr === 'two':
            message = two || many || '';
            break;
        case pr === 'few':
            message = few || many || '';
            break;
        default:
            message = '';
    }
    return `${val} ${message}`;
};

export const showAttendeesNamesOnly = (attendees?: IAttendee[]): string => {
    if (!attendees?.length) {
        return '';
    }

    return attendees.map(person => person.name).join(', ');
};

export const contactName = (contact: IContactPerson) => getContactDisplayName(contact);

export const valOrNone = (val: string | number) => (!val || val === '' ? '(none)' : val);

export const legacyStateFilter = (val: string) => {
    const newVal = val.replace('#Estate.', '');
    if (newVal === 'Purchasing' || newVal === 'Renovation') {
        return 'Done';
    }
    return newVal;
};
export const docTranslate = (docName: string, fallbackFormatterFn?: (n: string) => string): string => {
    if (!docName) {
        return '';
    }
    return documentTranslations[docName] || (fallbackFormatterFn ? fallbackFormatterFn(docName) : docName);
};

export const addressLine = (object: { location?: Pick<ILocation, 'street' | 'houseNumber'> }): string =>
    `${object.location?.street ? object.location.street : 'N/A'} ${object.location?.houseNumber ? object.location.houseNumber : ''}`;

export const enumToHuman = toTitleCase;
export const mediaCategoryToHuman = (value: MediaFileCategory) => {
    return MediaFileCategoryTranslations[value];
};

export const statesColor = (value: string, type: string) => {
    if (statesColorMap[type]) {
        return statesColorMap[type][value];
    }
    return 'is-info';
};

export const eventToHuman = (event: string) => get(eventsAlternativeNames, event, toTitleCase(event));
export const mfhEventToHuman = (event: string) => get(mfhEventsAlternativeNames, event, toTitleCase(event));
export const mfhBuildingEventToHuman = (event: string) => get(mfhBuildingEventsAlternativeNames, event, toTitleCase(event));
export const mfhPortfolioEventToHuman = (event: string) => get(mfhPortfolioEventsAlternativeNames, event, toTitleCase(event));
export const invoiceEventToHuman = (event: string) => get(invoiceEventAlternativeNames, event, toTitleCase(event));

export const interiorQualityShort = (interiorQuality: InteriorQuality): string => {
    switch (interiorQuality) {
        case InteriorQuality.Luxury:
            return 'LUX';
        case InteriorQuality.Normal:
            return 'NOR';
        case InteriorQuality.Simple:
            return 'SIM';
        case InteriorQuality.Sophisticated:
            return 'SOP';
        case InteriorQuality.NA:
        case InteriorQuality.NoInformation:
            return 'NOI';
        default:
            return '';
    }
};

export const markBoolean = (value: boolean): string => (value ? '√' : '');

export const percentOrAbsolute = (val?: string | number): string => {
    if (!val) {
        return '';
    }
    if (+val > 150) {
        return currency(val);
    }
    return percent(val as number) as string;
};

export const truncate = (val: string | undefined, limit: number): string => {
    if (!val) {
        return val;
    }
    if (val.length > limit) {
        return `${val.substring(0, limit - 3)}...`;
    }

    return val;
};

// Removes the config-version prefix and  omits the first element which is always the root config
export const trimConfigVersion = (encodedIdPath: string) => {
    const [, parts] = encodedIdPath.split(':');
    if (!parts) {
        return `v.${encodedIdPath}`;
    }
    const [rootConfig, ...rest] = parts.split('_');
    if (!rest.length) {
        return `v.${rootConfig}`;
    }
    return `v.${rest.join('_')}`;
};

export const enumToHumanDropdown = (object: any, transform?: (inp: string) => string, filter: (val: string) => Boolean = () => true) =>
    Object.values(object)
        .sort()
        .filter(filter)
        .map((val: string) => ({ text: transform ? transform(val) : enumToHuman(val), id: val }));

export const excludePercentageOnRenovationState = (state: RenovationStates): boolean => {
    const renovationStatusForExcludingPercentage = [
        RenovationStates.Identified,
        RenovationStates.Measured,
        RenovationStates.Modelled,
        RenovationStates.Briefed,
        RenovationStates.Planned,
        RenovationStates.Prepared,
        RenovationStates.CapturedWithContractor,
        RenovationStates.DefectsPending,
        RenovationStates.InWarranty,
        RenovationStates.CompletionRequested,
    ];
    const states = new Set(renovationStatusForExcludingPercentage);
    return states.has(state);
};
