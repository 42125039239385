import { getClient } from '@/api/api-client';
import {
    type ICommonTableFetchParams,
    type IEstate,
    type IInvestment,
    type IRentingDetails,
    type IRentingEstate,
    InvestmentCase,
    RentingProcess,
} from '@condo/domain';
import type {
    FindRenalContractsQueryParams,
    FindRenalContractsQueryResponse,
    UpsertRentalContractMutationRequest,
    UpsertRentalContractMutationResponse,
} from '@condo/sdk/propx';

export const findRentingEstates = async (params?: ICommonTableFetchParams): Promise<{ estates: IRentingEstate[]; total: number }> =>
    getClient('basecamp')
        .get('/renting/estates', { params })
        .then(response => {
            const { estates, total } = response.data;
            const estatesWithRentingProcess = estates.map(estate => ({
                ...estate,
                renting: estate.renting ? new RentingProcess({ ...estate.renting, events: estate.calendarEvents }) : undefined,
                executedInvestmentCase: estate.executedInvestmentCase
                    ? new InvestmentCase({ ...(estate.executedInvestmentCase as IInvestment), estate })
                    : undefined,
            }));
            return { estates: estatesWithRentingProcess, total };
        });

/*Renting*/
export const getRentingDetailsOfEstate = async (estate: IEstate): Promise<IRentingDetails[]> => {
    const { rentingDetails } = await getClient('propx').getRentingDetailsOfEstate({ estateId: estate.estateId });
    return rentingDetails.map(rd => {
        return {
            ...rd,
            executedInvestmentCase: rd.executedInvestmentCase ? new InvestmentCase({ ...(rd.executedInvestmentCase as IInvestment), estate }) : undefined,
        };
    }) as IRentingDetails[];
};

export const fetchEstateRentalContracts = async (estateId: number): Promise<FindRenalContractsQueryResponse['rentalContracts']> => {
    const { rentalContracts } = await fetchRentalContracts({ query: { estateId } });
    return rentalContracts;
};

export const fetchRentalContracts = async (params: FindRenalContractsQueryParams): Promise<FindRenalContractsQueryResponse> =>
    getClient('propx').findRenalContracts(params);

export const createOrUpdateRentalContract = async (
    rentalContract: UpsertRentalContractMutationRequest['rentalContract'],
    rentTenancyId?: number,
): Promise<UpsertRentalContractMutationResponse> => getClient('propx').upsertRentalContract({ rentalContract, rentTenancyId });
