import { fetchDurableJob, fetchDurableJobs, fetchDurableJobsList, fetchUnseenDurableJobs } from '@/api/durable-jobs.api';
import { DurableJobExpandOpts, DurableJobStatus, type IDurableJob, type IFetchDurableJobsParams } from '@condo/domain';
import { Row, Shape, ShapeStream } from '@electric-sql/client';
import { useQuery } from '@tanstack/vue-query';
import { camelCase, mapKeys, orderBy } from 'lodash-es';
import { type MaybeRef, Ref, computed, onBeforeUnmount, ref, unref } from 'vue';
import { useRootAppConfig } from './config.hooks';
import { useSelfUser } from './user.hooks';

export const useDurableJobs = (paramsOrRef?: MaybeRef<IFetchDurableJobsParams>) => {
    const params = computed(() => unref(paramsOrRef));
    return useQuery(['durable-jobs', params], async () => fetchDurableJobs(params.value), {
        initialData: {
            durableJobs: [],
            total: 0,
        },
        refetchInterval: 5_000,
    });
};

export const useDurableJob = (idOrRef?: MaybeRef<string>) => {
    const id = typeof idOrRef === 'string' ? idOrRef : unref(idOrRef);
    return useQuery(['durable-job-by-id', id], async () => fetchDurableJob(id), {
        initialData: null,
    });
};

export const useUnseenDurableJobs = (expand?: DurableJobExpandOpts): [Ref<IDurableJob[]>, () => Promise<any>] => {
    const config = useRootAppConfig();

    if (config.value.JOBS_PROVIDER === 'electric') {
        console.log('[Durable Jobs] Using electricsql');
        const { subscription, jobs } = useElectricJobs();

        onBeforeUnmount(() => {
            subscription.unsubscribe();
        });

        return [jobs, () => Promise.resolve()];
    }

    console.log('[Durable Jobs] Using polling');

    const refetchJitterMinMS = 500;
    const refetchJitterMaxMS = 1500;

    const { data, refetch } = useQuery(['durable-jobs', 'unseen'], async () => fetchUnseenDurableJobs(expand), {
        initialData: {
            durableJobs: [],
            total: 0,
        },
        refetchInterval: () => {
            const jitter = Math.floor(Math.random() * (refetchJitterMaxMS - refetchJitterMinMS + 1) + refetchJitterMinMS);
            return config.value.JOBS_POLLING_INTERVAL_MS + jitter;
        },
    });

    const durableJobs = computed(() => data.value.durableJobs);
    return [durableJobs, refetch];
};

export const useDurableJobsList = () => {
    return useQuery(['durable-jobs', 'list'], async () => fetchDurableJobsList(), {
        initialData: [],
        refetchInterval: 5_000,
    });
};

const notificationStatuses = [DurableJobStatus.pending, DurableJobStatus.fail, DurableJobStatus.success];

export const useElectricJobs = () => {
    const user = useSelfUser();
    const config = useRootAppConfig();
    const jobs = ref<IDurableJob[]>([]);

    if (!user.value?.userId) {
        throw new Error(`Must have userId attached to self-user to watch jobs`);
    }
    if (!config.value.S_ELECTRIC_URL) {
        throw new Error(`Must have S_ELECTRIC_URL defined in config when using electric-sql`);
    }

    // @todo it somehow doesnt consistently work w/ --> AND "status" IN (${notificationStatuses.map(s => `'${s}'`).join(',')})
    const stream = new ShapeStream<Row<IDurableJob>>({
        url: `${config.value.S_ELECTRIC_URL}/v1/shape`,
        headers:
            config.value.ELECTRIC_USERNAME && config.value.ELECTRIC_PASSWORD
                ? {
                      Authorization: `Basic ${btoa(`${config.value.ELECTRIC_USERNAME}:${config.value.ELECTRIC_PASSWORD}`)}`,
                  }
                : undefined,
        params: {
            table: `__durable_jobs`,
            where: `created_by='${user.value.userId}' AND seen_at IS NULL`,
            columns: [
                'id',
                'name',
                'status',
                'step',
                'total_steps',
                'step_name',
                'created_by',
                'started_at',
                'scheduled_at',
                'finished_at',
                'seen_at',
                'updated_at',
                'created_at',
                'meta',
            ],
        },
    });
    const shape = new Shape(stream);

    const unsubscribe = shape.subscribe(payload => {
        if (!payload.rows.length) {
            jobs.value = [];
            return;
        }
        const newJobs = payload.rows.map(m => mapKeys(m as Record<string, any>, (_k, v) => camelCase(v)) as IDurableJob);
        jobs.value = orderBy(
            newJobs.filter(j => notificationStatuses.includes(j.status) && !j.seenAt),
            'createdAt',
            'desc',
        );
    });

    return { subscription: { unsubscribe }, jobs };
};
