import { fetchMfhBuilding } from '@/api/mfh';
import { getStrippedCCSRoutes } from '@condo/domain';
import type { RouteRecordRaw } from 'vue-router';

const CCSRouteMap = getStrippedCCSRoutes();

export const createMfhRoutes = (): RouteRecordRaw[] => [
    {
        ...CCSRouteMap['one.mfh-by-building.detail'],
        component: () => import('@/views/mfh/OneMfh.vue'),
        async beforeEnter(to, _from, next) {
            const mfhBuilding = await fetchMfhBuilding(+to.params.mfhBuildingId, { select: ['mfhBuildingId', 'mfhId'] });
            next(`/mfhs/${mfhBuilding.mfhId}/detail`);
        },
    },
    {
        ...CCSRouteMap['one.mfh'],
        redirect: params => `${params.fullPath}/${CCSRouteMap['one.mfh.detail'].path}`,
        components: {
            default: () => import('@/views/mfh/OneMfh.vue'),
        },
        props: {
            default: route => ({ mfhId: +route.params.mfhId }),
        },
        children: [
            {
                ...CCSRouteMap['one.mfh.summary'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhSummary.vue'),
                },
            },
            {
                ...CCSRouteMap['one.mfh.detail'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhDetails.vue'),
                },
            },
            {
                ...CCSRouteMap['one.mfh.tasks'],
                components: {
                    default: () => import('@/components/tasks/TasksTable.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.media'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhMedia.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.contacts'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhContacts.vue'),
                },
            },
            {
                ...CCSRouteMap['one.mfh.documents'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhDocuments.vue'),
                },
            },
            {
                ...CCSRouteMap['one.mfh.calendar-events'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhCalendarEvents.vue'),
                },
            },
            {
                ...CCSRouteMap['one.mfh.ai-imports'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhAIImports.vue'),
                },
            },
            {
                ...CCSRouteMap['one.mfh.estates'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhEstates.vue'),
                },
            },
            {
                ...CCSRouteMap['one.mfh.timeline'],
                components: {
                    default: () => import('@/components/timeline/EventsTimeline.vue'),
                },
                props: {
                    default: route => ({ ...route.params }),
                },
            },
            {
                ...CCSRouteMap['one.mfh.sourcing.overview'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhSourcingOverview.vue'),
                },
                props: true,
            },
            /*DD Section*/
            {
                ...CCSRouteMap['one.mfh.dd.document-checklist'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhDocumentsChecklist.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.dd.overview'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhDDOverview.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.dd.questions-and-answers'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhOpenQuestions.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.dd.sourcing-checklist'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhSourcingChecklist.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.dd.object-checklist'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhObjectChecklist.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.dd.vicinity-checklist'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhVicinityChecklist.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.dd.rent-checklist'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhRentChecklist.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.dd.technical-checklist'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhTechnicalChecklist.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.dd.technical-onsite-checklist'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhTechnicalOnSiteChecklist.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.dd.legal-checklist'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhLegalChecklist.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.dd.commercial-checklist'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhCommercialChecklist.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.dd.deal-closing-checklist'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhDealClosingChecklist.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.dd.rent-roll'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhRentRoll.vue'),
                },
                props: true,
            },
            /*---END DD Section---*/
            {
                ...CCSRouteMap['one.mfh.purchasing.overview'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhPurchasingOverview.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.purchasing.investment-case-overview'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/mfh-investment-case/MfhInvestmentcaseOverview.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.purchasing.investment-case-detail'],
                components: {
                    default: () => import('@/views/mfh/MfhInvestmentcaseDetail.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.subsidy-financing.overview'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhSubsidyFinancingOverview.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.estate-management.overview'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhEstateManagementOverview.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh.owner-management.overview'],
                components: {
                    default: () => import('@/components/mfh/one-mfh/MfhOwnerManagementOverview.vue'),
                },
                props: true,
            },
        ],
    },
];
