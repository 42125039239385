import { fetchEstateIdByCondoUuid, fetchEstateIdByOriginalId } from '@/api/basecamp.api';
import { useRecentPageTracker } from '@/hooks';
import { accountingRoutes } from '@/router/accounting.routes';
import { createBankAccountRoutes } from '@/router/bank-account.routes';
import { createCompanyRoutes } from '@/router/company.routes';
import { configRoutes } from '@/router/config.routes';
import { createContactRoutes } from '@/router/contact.routes';
import { createEstateRoutes } from '@/router/estate.routes';
import { financeRoutes } from '@/router/finance.routes';
import { createMfhBuildingRoutes } from '@/router/mfh-building.routes';
import { createMfhPortfolioRoutes } from '@/router/mfh-portfolio.routes';
import { operationRoutes } from '@/router/operation.routes';
import { createPortfolioRoutes } from '@/router/portfolio.routes';
import { store } from '@/store';
import { getStrippedCCSRoutes } from '@condo/domain';
import { createRouter, createWebHistory } from 'vue-router';
import { creditLinePortfolioRoutes } from './credit-line-portfolio.routes';
import { createMfhRoutes } from './mfh.routes';
import { notificationRoute } from './notification.routes';
import { getDefaultFilterQuery } from './route-helpers';
import { salesPackageRoutes } from './sales-package.routes';

const CCSRouteMap = getStrippedCCSRoutes();

const getRouter = () =>
    createRouter({
        history: createWebHistory(),
        linkActiveClass: 'open active',
        scrollBehavior(to) {
            if (to.hash) {
                return new Promise(resolve => {
                    setTimeout(() => {
                        resolve({
                            el: to.hash,
                            top: 100,
                            behavior: 'smooth',
                        });
                    }, 1000);
                });
            }

            return { top: 0 };
        },
        routes: [
            /*Root routes*/
            {
                name: 'root',
                path: '/',
                redirect: CCSRouteMap['all.estates'].path,
            },
            {
                ...CCSRouteMap['all.mfh'],
                component: () => import('@/views/mfh/AllMfhs.vue'),
            },
            {
                ...CCSRouteMap['renovation.mfh-buildings'],
                component: () => import('@/views/mfh/MfhBuildingRenovation.vue'),
            },
            {
                ...CCSRouteMap.calendar,
                component: () => import('@/views/GlobalCalendar.vue'),
                props: route =>
                    // We need to copy data, otherwise router properly would be updated by observer
                    ({ filters: getDefaultFilterQuery(route.query) }),
            },
            {
                ...CCSRouteMap.defects,
                component: () => import('@/views/GlobalDefects.vue'),
            },
            {
                ...CCSRouteMap['all.estates'],
                component: () => import('@/views/AllEstates.vue'),
            },
            {
                ...CCSRouteMap.selling,
                component: () => import('@/views/Selling.vue'),
            },
            {
                ...CCSRouteMap.renting,
                component: () => import('@/views/Renting.vue'),
            },
            {
                ...CCSRouteMap.firstNormalRenting,
                component: () => import('@/views/Renting.vue'),
            },
            {
                ...CCSRouteMap.sharedRenting,
                component: () => import('@/views/Renting.vue'),
            },
            {
                ...CCSRouteMap.furnishedRenting,
                component: () => import('@/views/Renting.vue'),
            },
            {
                ...CCSRouteMap.additionalNormalRenting,
                component: () => import('@/views/Renting.vue'),
            },
            {
                ...CCSRouteMap['renting.rental-contracts'],
                component: () => import('@/views/RentalContracts.vue'),
            },
            {
                ...CCSRouteMap['sourcing.estates'],
                component: () => import('@/views/EstateSourcing.vue'),
            },
            {
                ...CCSRouteMap['sourcing.mfhs'],
                component: () => import('@/views/mfh/MfhSourcing.vue'),
            },
            {
                ...CCSRouteMap['purchasing.mfhs'],
                component: () => import('@/views/mfh/MfhPurchasing.vue'),
            },
            {
                ...CCSRouteMap['sourcing.eligible-assets'],
                component: () => import('@/views/mfh/EligibleAssets.vue'),
            },
            {
                ...CCSRouteMap['sourcing.pipeline'],
                component: () => import('@/views/mfh/AssetsInPipeline.vue'),
            },
            {
                ...CCSRouteMap['cashflow.list'],
                component: () => import('@/views/Cashflow.vue'),
                props: true,
            },
            {
                ...CCSRouteMap['property-management.monthly-investor-share'],
                component: () => import('@/views/MonthlyInvestorShare.vue'),
            },
            {
                ...CCSRouteMap['property-management.monthly-rent-guarantees'],
                component: () => import('@/views/MonthlyRentGuarantees.vue'),
            },
            {
                ...CCSRouteMap['property-management.investor-share-batch-review'],
                component: () => import('@/components/processes/property-management/InvestorShareBatchReview.vue'),
                props: true,
            },
            {
                ...CCSRouteMap['property-management.tenancy-overview'],
                component: () => import('@/views/TenancyOverview.vue'),
            },
            {
                ...CCSRouteMap['property-management.tenants-overview'],
                component: () => import('@/views/TenantsOverview.vue'),
            },
            {
                ...CCSRouteMap['property-management.tasks-overview'],
                component: () => import('@/views/PropertyManagementTasksOverview.vue'),
            },
            {
                ...CCSRouteMap['bank.accounts.list'],
                component: () => import('@/views/BankAccounts.vue'),
            },
            {
                ...CCSRouteMap.tasks,
                component: () => import('@/components/tasks/GlobalTaskList.vue'),
            },
            {
                ...CCSRouteMap['selling.eligible-estates'],
                component: () => import('@/components/sales-package/SalesPackageEligibleEstates.vue'),
            },
            {
                ...CCSRouteMap['selling.sales-packages'],
                component: () => import('@/components/sales-package/SalesPackageList.vue'),
            },
            {
                ...CCSRouteMap['selling.data-checklist'],
                component: () => import('@/components/processes/selling/SellingDataChecklist.vue'),
            },
            {
                ...CCSRouteMap['selling.estates-data'],
                component: () => import('@/components/processes/selling/SellingEstatesData.vue'),
            },
            {
                ...CCSRouteMap['selling.documents'],
                component: () => import('@/components/processes/selling/SellingDocuments.vue'),
            },
            {
                ...CCSRouteMap['finance.eligible.estates'],
                component: () => import('@/components/portfolio/list/EligibleEstates.vue'),
            },
            {
                ...CCSRouteMap['portfolio.list'],
                component: () => import('@/components/portfolio/list/PortfolioList.vue'),
            },
            {
                ...CCSRouteMap['save.portfolio'],
                component: () => import('@/components/portfolio/SavePortfolio.vue'),
                props: route => ({
                    portfolioId: +route.params.portfolioId,
                    estateIds: Array.isArray(route.query.estates) ? route.query.estates : [route.query.estates],
                }),
            },
            {
                ...CCSRouteMap.purchasing,
                component: () => import('@/views/Purchasing.vue'),
            },
            {
                ...CCSRouteMap['purchasing.documents'],
                component: () => import('@/components/processes/purchasing/PurchasingDocuments.vue'),
                props: route => ({ filter: getDefaultFilterQuery(route.query) }),
            },
            {
                ...CCSRouteMap.contacts,
                component: () => import('@/components/contact/ContactList.vue'),
                props: route => ({ filter: getDefaultFilterQuery(route.query) }),
            },
            {
                ...CCSRouteMap.companies,
                component: () => import('@/components/company/CompanyList.vue'),
                props: route => ({ filter: route.params?.filter || getDefaultFilterQuery(route.query) }),
            },
            {
                ...CCSRouteMap.propertyManagement,
                component: () => import('@/views/PropertyManagement.vue'),
                props: route => ({ filter: getDefaultFilterQuery(route.query) }),
            },
            {
                ...CCSRouteMap['property-management.estate-management'],
                component: () => import('@/views/EstateManagement.vue'),
                props: route => ({ filter: getDefaultFilterQuery(route.query) }),
            },
            {
                ...CCSRouteMap['property-management.documents'],
                component: () => import('@/components/processes/property-management/PropertyManagementDocuments.vue'),
            },
            {
                ...CCSRouteMap.renovation,
                component: () => import('@/views/Renovation.vue'),
            },
            {
                ...CCSRouteMap['external-renovation'],
                component: () => import('@/components/estate-list/ExternallyRenovatedEstatesList.vue'),
            },
            {
                ...CCSRouteMap['document-requests'],
                component: () => import('@/components/document-request/DocumentRequestsOverview.vue'),
            },
            {
                ...CCSRouteMap['contractor-capacity-overview'],
                component: () => import('@/components/processes/renovation/ContractorCapacityOverview.vue'),
            },
            {
                ...CCSRouteMap['renovation.cost-tracking'],
                component: () => import('@/components/processes/renovation/CostTracking.vue'),
            },
            {
                ...CCSRouteMap['estate.renovation.cost-tracking'],
                component: () => import('@/components/processes/renovation/CostTrackingForEstate.vue'),
            },
            {
                ...CCSRouteMap['oneEstateByCondoUuid'],
                component: () => import('@/views/OneEstate.vue'),
                async beforeEnter(to, _from, next) {
                    const estateId = await fetchEstateIdByCondoUuid(to.params.condoUuid as string);

                    const redirect = to.params.redirect as string[] | undefined;
                    const subRoute = redirect?.length ? `/${redirect.join('/')}` : '';

                    const queryParams = Object.keys(to.query).length > 0 ? new URLSearchParams(to.query as Record<string, string>) : null;
                    const queryString = queryParams ? `?${queryParams.toString()}` : '';

                    next(`/estates/${estateId}${subRoute}${queryString}`);
                },
            },
            {
                ...CCSRouteMap['oneEstateByOriginalId'],
                component: () => import('@/views/OneEstate.vue'),
                async beforeEnter(to, _from, next) {
                    const estateId = await fetchEstateIdByOriginalId(to.params.originalId as string);
                    const redirect = to.params.redirect as string[] | undefined;
                    const subRoute = redirect?.length ? `/${redirect.join('/')}` : '';

                    const queryParams = Object.keys(to.query).length > 0 ? new URLSearchParams(to.query as Record<string, string>) : null;
                    const queryString = queryParams ? `?${queryParams.toString()}` : '';

                    next(`/estates/${estateId}${subRoute}${queryString}`);
                },
            },
            createBankAccountRoutes(store),
            createEstateRoutes(store),
            createPortfolioRoutes(store),
            createCompanyRoutes(),
            createContactRoutes(store),
            ...createMfhRoutes(),
            ...createMfhBuildingRoutes(),
            ...createMfhPortfolioRoutes(),
            configRoutes,
            accountingRoutes,
            creditLinePortfolioRoutes,
            salesPackageRoutes,
            ...financeRoutes,
            ...operationRoutes,
            notificationRoute,
            {
                path: '/:catchAll(.*)*',
                component: () => import('@/views/ErrorNotFound.vue'),
            },
        ],
    });

export const router = getRouter();
const { logGeneralPage } = useRecentPageTracker();

router.afterEach(to => {
    const parentRoute = to.matched?.[0]?.name ?? to.name;
    switch (parentRoute) {
        case CCSRouteMap['estate.root'].name:
            break;
        case CCSRouteMap['one.mfh'].name:
            break;
        default:
            logGeneralPage(to);
    }
});
