import { contactsStore } from '@/store/modules/contacts.store';
import { type IDictionariesStore, dictionariesStore } from '@/store/modules/dictionaries.store';
import { type IDocumentInspectionStore, documentInspectionStore } from '@/store/modules/document-inspection.store';
import { type IFileStore, documentsStore } from '@/store/modules/documents.store';
import { type ICondoEventsStore, eventsStore } from '@/store/modules/events.store';
import { type IFinanceStore, financeStore } from '@/store/modules/finance.store';
import { type IGeneralDataStore, generalDataStore } from '@/store/modules/generic.store';
import { type IInvestmentStore, investmentCasesStore } from '@/store/modules/investments.store';
import { invoicesStore } from '@/store/modules/invoices.store';
import { type IPortfolioStore, portfolioStore } from '@/store/modules/portfolio.store';
import { predictionsStore } from '@/store/modules/predictions.store';
import { type IPropertyManagementStore, propertyManagementStore } from '@/store/modules/property-management.store';
import { type IPurchasingStore, purchasingStore } from '@/store/modules/purchasing.store';
import { type IRenovationStore, renovationStore } from '@/store/modules/renovation.store';
import { type IRentingStore, rentingStore } from '@/store/modules/renting.store';
import { type IRuleStore, ruleStore } from '@/store/modules/rule.store';
import { type IUserCompanyFunctionStore, userCompanyFunctionStore } from '@/store/modules/user-company-functions.store';
import { type IUsersStore, usersStore } from '@/store/modules/users.store';
import type { InjectionKey } from 'vue';
import { type Store, createStore } from 'vuex';
import { type IDefectsStore, defectsStore } from './modules/defects.store';
import { IDurableJobStore, durableJobStore } from './modules/durable-job.store';
import { type IEstatesStore, estatesStore } from './modules/estates.store';
import { type IMediaStore, mediaStore } from './modules/media.store';
import { type IMFHCalendarStore, mfhCalendarEventsStore } from './modules/mfh/mfh-calendar-events.store';
import { type IRegionStore, regionStore } from './modules/region.store';
import { type IRentableUnitStore, rentableUnitStore } from './modules/rentable-unit.store';
import { type ISellingStore, sellingStore } from './modules/selling.store';
import { type ITasksStore, tasksStore } from './modules/tasks.store';
import { type IUIStore, uiStore } from './modules/ui.store';
import { type IUserStore, userStore } from './modules/user.store';

//TODO: define other stores.
export interface ICockpitStore {
    media: IMediaStore;
    estates: IEstatesStore;
    users: IUsersStore;
    tasks: ITasksStore;
    user: IUserStore;
    ui: IUIStore;
    durableJob: IDurableJobStore;
    documents: IFileStore;
    renovation: IRenovationStore;
    defects: IDefectsStore;
    generalData: IGeneralDataStore;
    propertyManagement: IPropertyManagementStore;
    purchasing: IPurchasingStore;
    portfolio: IPortfolioStore;
    investmentCases: IInvestmentStore;
    renting: IRentingStore;
    selling: ISellingStore;
    finance: IFinanceStore;
    region: IRegionStore;
    rule: IRuleStore;
    rentableUnit: IRentableUnitStore;
    documentInspection: IDocumentInspectionStore;
    userCompanyFunction: IUserCompanyFunctionStore;
    dictionaries: IDictionariesStore;
    mfhCalendarEvents: IMFHCalendarStore;
    eventsStore: ICondoEventsStore;
}

export const CCSStoreKey: InjectionKey<Store<ICockpitStore>> = Symbol('CCS_STORE');

export const store = createStore<ICockpitStore>({
    modules: {
        estates: estatesStore,
        media: mediaStore,
        user: userStore,
        tasks: tasksStore,
        investmentCases: investmentCasesStore,
        predictions: predictionsStore,
        ui: uiStore,
        durableJob: durableJobStore,
        users: usersStore,
        mfhCalendarEvents: mfhCalendarEventsStore,
        documents: documentsStore,
        renovation: renovationStore,
        defects: defectsStore,
        generalData: generalDataStore,
        propertyManagement: propertyManagementStore,
        purchasing: purchasingStore,
        invoices: invoicesStore,
        selling: sellingStore,
        portfolio: portfolioStore,
        contacts: contactsStore,
        renting: rentingStore,
        finance: financeStore,
        region: regionStore,
        rule: ruleStore,
        rentableUnit: rentableUnitStore,
        documentInspection: documentInspectionStore,
        userCompanyFunction: userCompanyFunctionStore,
        dictionaries: dictionariesStore,
        eventsStore: eventsStore,
    },
});
