import { isNil } from 'lodash-es';

export interface IDurableJobStore {
    isJobSidebarOpen: boolean;
    selectedSidebarJobId: number | null;
}

function getInitialState(): IDurableJobStore {
    return {
        isJobSidebarOpen: false,
        selectedSidebarJobId: null,
    };
}

export const durableJobStore = {
    namespaced: true,
    state: getInitialState(),
    getters: {
        selectedSidebarJobId(state: IDurableJobStore) {
            return state.selectedSidebarJobId;
        },
        isJobSidebarOpen(state: IDurableJobStore) {
            return state.isJobSidebarOpen;
        },
    },
    mutations: {
        toggleJobSidebar(state: IDurableJobStore, loadingState?: boolean) {
            if (isNil(loadingState)) {
                state.isJobSidebarOpen = !state.isJobSidebarOpen;
            } else {
                state.isJobSidebarOpen = loadingState;
            }
        },
        setSelectedSidebarJobId(state: IDurableJobStore, jobId: number | null) {
            state.selectedSidebarJobId = jobId;
            state.isJobSidebarOpen = !isNil(jobId);
        },
    },
};
