import type { ICockpitStore } from '@/store';
import { DefectType, ProcessType, getStrippedCCSRoutes } from '@condo/domain';
import type { RouteRecordRaw } from 'vue-router';
import type { Store } from 'vuex';
import { getDefaultFilterQuery } from './route-helpers';

const CCSRouteMap = getStrippedCCSRoutes();

export const createEstateRoutes = (store: Store<ICockpitStore>): RouteRecordRaw => ({
    ...CCSRouteMap['estate.root'],
    redirect: params => `${params.fullPath}/${CCSRouteMap.oneEstate.path}`,
    component: () => import('@/views/OneEstate.vue'),
    props: true,
    children: [
        {
            ...CCSRouteMap.oneEstate,
            components: {
                default: () => import('@/components/one-estate/EstateDetails.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap.marketDetails,
            components: {
                default: () => import('@/components/one-estate/market-details/MarketDetails.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap.prediction,
            components: {
                default: () => import('@/components/one-estate/predictions/Predictions.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['estate-contacts'],
            components: {
                default: () => import('@/components/one-estate/Contacts.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['investment.list'],
            components: {
                default: () => import('@/components/investment-case/InvestmentCasesList.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['selling.investment.list'],
            components: {
                default: () => import('@/components/investment-case/SellingInvestmentCases.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['selling.cashflow.list'],
            components: {
                default: () => import('@/components/sales-package/InvestorCashflowList.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['selling.cashflow.one'],
            components: {
                default: () => import('@/components/sales-package/InvestorCashflowDetail.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['property-management.overview'],
            components: {
                default: () => import('@/components/processes/property-management/PropertyManagementOverview.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['property-management.financial-data'],
            components: {
                default: () => import('@/components/processes/property-management/PropertyManagementFinancialData.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['property-management.tasks'],
            components: {
                default: () => import('@/components/processes/ProcessTaskList.vue'),
            },
            props: {
                default: route => ({ ...route.params, processType: ProcessType.TenantManagement }),
            },
        },
        {
            ...CCSRouteMap['property-management.investor-info'],
            components: {
                default: () => import('@/components/processes/property-management/TenancyInfo/TenancyInfo.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['property-management.rent-tenancy'],
            components: {
                default: () => import('@/components/processes/property-management/TenanciesManagement.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['property-management.estate'],
            components: {
                default: () => import('@/components/processes/property-management/PropertyManagementEstate.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['property-management.rules'],
            components: {
                default: () => import('@/components/processes/property-management/PropertyManagementRules.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['estate-management'],
            redirect: `${CCSRouteMap['estate.root'].path}/${CCSRouteMap['estate-management.overview'].path}`,
        },
        {
            ...CCSRouteMap['estate-management.defects'],
            components: {
                default: () => import('@/components/construction/Defects.vue'),
            },
            props: {
                default: route => ({ ...route.params, type: DefectType.PropertyManagement }),
            },
        },
        {
            ...CCSRouteMap['estate-management.overview'],
            components: {
                default: () => import('@/components/processes/estate-management/EstateManagementOverview.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['estate-management.tasks'],
            components: {
                default: () => import('@/components/processes/ProcessTaskList.vue'),
            },
            props: {
                default: route => ({ ...route.params, processType: ProcessType.EstateManagement }),
            },
        },
        {
            ...CCSRouteMap['owner-management'],
            redirect: `${CCSRouteMap['estate.root'].path}/${CCSRouteMap['owner-management.overview'].path}`,
        },
        {
            ...CCSRouteMap['property-management.all-tasks'],
            components: {
                default: () => import('@/components/processes/property-management/overall/AllPropertyManagementTasks.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['property-management.investor-data'],
            components: {
                default: () => import('@/components/processes/property-management/InvestorInfoOverview.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['estate-management.sepa-mandates'],
            components: {
                default: () => import('@/components/processes/property-management/EstateSepaMandates.vue'),
            },
            props: true,
        },
        /*End Property Management*/
        {
            ...CCSRouteMap['owner-management.overview'],
            components: {
                default: () => import('@/components/processes/owner-management/OwnerManagementOverview.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['owner-management.tasks'],
            components: {
                default: () => import('@/components/processes/ProcessTaskList.vue'),
            },
            props: {
                default: route => ({ ...route.params, processType: ProcessType.OwnerManagement }),
            },
        },
        {
            ...CCSRouteMap['owner-management.investor-share-history'],
            components: {
                default: () => import('@/components/processes/owner-management/InvestorShareHistory.vue'),
            },
            props: {
                default: route => ({ ...route.params }),
            },
        },
        {
            ...CCSRouteMap['investment.edit'],
            components: {
                default: () => import('@/components/investment-case/InvestmentCase.vue'),
            },
            props: {
                default: route => ({ estateId: +route.params.estateId, investmentId: +route.params.investmentId }),
            },
            async beforeEnter(to, from, next) {
                const {
                    params: { investmentId, estateId },
                } = to;
                await store.dispatch('investmentCases/loadInvestmentData', { investmentId, estateId }); //.then(next);
                next();
            },
        },
        {
            ...CCSRouteMap.inspections,
            components: {
                default: () => import('@/components/one-estate/Checks.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['inspections.purchasing'],
            components: {
                default: () => import('@/components/one-estate/purchasing-inspection/PurchasingInspection.vue'),
            },
            props: {
                default: route => ({ estateId: +route.params.estateId }),
            },
        },
        {
            ...CCSRouteMap['inspections.document'],
            components: {
                default: () => import('@/components/one-estate/document-inspection/DocumentInspection.vue'),
            },
            props: {
                default: route => ({ documentId: +route.params.documentId, estateId: +route.params.estateId }),
            },
        },
        {
            ...CCSRouteMap.documents,
            components: {
                default: () => import('@/components/documents/Documents.vue'),
                estateTop: () => import('@/components/documents/DocumentsSummary.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['to-do'],
            components: {
                default: () => import('@/components/tasks/TasksTable.vue'),
            },
            props: {
                default: { level: 'estate' },
            },
        },
        {
            ...CCSRouteMap.timeline,
            components: {
                default: () => import('@/components/timeline/EventsTimeline.vue'),
            },
            props: {
                default: route => ({ ...route.params }),
            },
        },
        {
            ...CCSRouteMap.elements,
            components: {
                estateTop: () => import('@/components/processes/renovation/RenovationSummary.vue'),
                default: () => import('@/components/estate-elements/EstateElementsGrid.vue'),
            },
        },
        {
            ...CCSRouteMap.calendarEvents,
            components: {
                default: () => import('@/components/one-estate/EstateCalendarEvents.vue'),
            },
            props: {
                default: route =>
                    // We need to copy data, otherwise router properly would be updated by observer
                    ({ filters: getDefaultFilterQuery(route.query) }),
            },
        },
        {
            ...CCSRouteMap['sourcing.current'],
            components: {
                default: () => import('@/components/processes/SourcingOverview.vue'),
            },
            props: {
                default: route => ({ processId: +route.params.processId }),
            },
        },
        {
            ...CCSRouteMap['purchasing.current'],
            components: {
                default: () => import('@/components/processes/PurchasingOverview.vue'),
            },
            props: {
                default: route => ({ processId: +route.params.processId }),
            },
        },
        {
            ...CCSRouteMap['renting.current'],
            components: {
                default: () => import('@/components/processes/renting/RentingOverview.vue'),
            },
            props: {
                default: route => ({ processId: +route.params.processId }),
            },
        },
        {
            ...CCSRouteMap['renting.leads'],
            components: {
                default: () => import('@/components/processes/renting/RentingLeadsTable.vue'),
            },
            props: {
                default: route => ({ rentingProcessId: +route.params.processId, estateId: +route.params.estateId }),
            },
        },
        {
            ...CCSRouteMap['renting.listing'],
            components: {
                default: () => import('@/components/processes/renting/RentingListings.vue'),
            },
            props: {
                default: route => ({ estateId: +route.params.estateId }),
            },
        },
        {
            ...CCSRouteMap['renting.estate-rental-contracts'],
            components: {
                default: () => import('@/components/processes/renting/EstateRentalContracts.vue'),
            },
            props: {
                default: route => ({ estateId: +route.params.estateId }),
            },
        },
        {
            ...CCSRouteMap['estate.livingsCheck'],
            components: {
                default: () => import('@/components/livings/LivingsCheck.vue'),
            },
            props: {
                default: route => ({ estateId: +route.params.estateId }),
            },
        },
        {
            ...CCSRouteMap['estate.furnishing'],
            components: {
                default: () => import('@/components/livings/Furnishing.vue'),
            },
            props: {
                default: route => ({ estateId: +route.params.estateId }),
            },
        },
        {
            ...CCSRouteMap['estate.livingsCostTracking'],
            components: {
                default: () => import('@/components/livings/LivingsCostTracking.vue'),
            },
            props: {
                default: route => ({ estateId: +route.params.estateId }),
            },
        },
        {
            ...CCSRouteMap['selling.current'],
            components: {
                default: () => import('@/components/processes/SellingOverview.vue'),
            },
            props: {
                default: route => ({ processId: +route.params.processId }),
            },
        },
        {
            ...CCSRouteMap['renovation.current'],
            components: {
                default: () => import('@/components/processes/renovation/RenovationOverview.vue'),
            },
            props: {
                default: route => ({ processId: route.params.processId }),
            },
        },
        {
            ...CCSRouteMap['renovation.floor-plan'],
            components: {
                estateTop: () => import('@/components/processes/renovation/RenovationSummary.vue'),
                default: () => import('@/components/processes/renovation/RenovationFloorPlan.vue'),
            },
            props: {
                default: route => ({ processId: route.params.processId }),
            },
        },
        {
            ...CCSRouteMap['renovation.project.plan'],
            components: {
                estateTop: () => import('@/components/processes/renovation/RenovationSummary.vue'),
                default: () => import('@/components/construction/ConstructionPlansList.vue'),
            },
            props: {
                default: route => ({ processId: route.params.processId }),
            },
        },
        {
            ...CCSRouteMap['renovation.inspection-grid'],
            components: {
                estateTop: () => import('@/components/processes/renovation/RenovationSummary.vue'),
                default: () => import('@/components/processes/renovation/InspectionQuestionsGrid.vue'),
            },
            props: {
                default: route => ({ processId: route.params.processId }),
            },
        },
        {
            ...CCSRouteMap['renovation.progress.tracking'],
            components: {
                estateTop: () => import('@/components/processes/renovation/RenovationSummary.vue'),
                default: () => import('@/components/construction/ProgressTracking.vue'),
            },
            props: {
                default: route => ({ processId: route.params.processId }),
            },
        },
        {
            ...CCSRouteMap['renovation.project.one.plan'],
            components: {
                default: () => import('@/components/construction/ConstructionPlan.vue'),
            },
            props: {
                default: route => ({ planId: route.params.planId }),
            },
        },
        {
            ...CCSRouteMap['renovation.inspection'],
            components: {
                default: () => import('@/components/processes/renovation/inspection/InspectionDetails.vue'),
            },
            props: {
                default: route => ({
                    estateId: route.params.estateId,
                    processId: route.params.processId,
                    renovationInspectionId: route.params.renovationInspectionId,
                }),
            },
        },
        {
            ...CCSRouteMap['renovation.inspection.crafts'],
            components: {
                default: () => import('@/components/processes/renovation/inspection/EditableInspectionDetails.vue'),
            },
            props: {
                default: route => ({
                    estateId: route.params.estateId,
                    processId: route.params.processId,
                    renovationInspectionId: route.params.renovationInspectionId,
                }),
            },
        },
        {
            ...CCSRouteMap['renovation.inspection.craft-details'],
            components: {
                default: () => import('@/components/processes/renovation/inspection/CraftDetails.vue'),
            },
            props: {
                default: route => ({
                    processId: route.params.processId,
                    renovationInspectionId: route.params.renovationInspectionId,
                    constructionCraft: route.params.constructionCraft,
                }),
            },
        },
        {
            ...CCSRouteMap['renovation.defects'],
            components: {
                estateTop: () => import('@/components/processes/renovation/RenovationSummary.vue'),
                default: () => import('@/components/construction/Defects.vue'),
            },
            props: {
                default: route => ({ ...route.params, type: DefectType.Renovation }),
            },
        },
        {
            ...CCSRouteMap['renovation.defects.one'],
            components: {
                estateTop: () => import('@/components/processes/renovation/RenovationSummary.vue'),
                default: () => import('@/components/construction/OneDefect.vue'),
            },
            props: {
                default: route => ({ defectId: +route.params.defectId }),
            },
        },
        {
            ...CCSRouteMap['one-estate.controlling'],
            component: () => import('@/components/one-estate/EstateAccounting.vue'),
            props: route => ({ filter: getDefaultFilterQuery(route.query) }),
        },
        {
            ...CCSRouteMap['one-estate.media'],
            component: () => import('@/components/one-estate/EstateMedia.vue'),
            props: route => ({ filter: getDefaultFilterQuery(route.query) }),
        },
        {
            ...CCSRouteMap['one-estate.invoice.details'],
            component: () => import('@/components/accounting/invoice-details/InvoiceDetails.vue'),
            props: true,
        },
        {
            ...CCSRouteMap['estate.payment-notice-summary'],
            component: () => import('@/components/payment-notice-summary/PaymentNoticeSummary.vue'),
            props: route => ({ estateId: +route.params.estateId }),
        },
    ],
});
