import { getStrippedCCSRoutes } from '@condo/domain';
import type { RouteRecordRaw } from 'vue-router';

const CCSRouteMap = getStrippedCCSRoutes();

export const createMfhPortfolioRoutes = (): RouteRecordRaw[] => [
    {
        ...CCSRouteMap['sourcing.mfh-portfolios'],
        component: () => import('@/views/mfh/MfhPortfolios.vue'),
    },
    {
        ...CCSRouteMap['one.mfh-portfolio'],
        redirect: params => `${params.fullPath}/${CCSRouteMap['one.mfh-portfolio.detail'].path}`,
        components: {
            default: () => import('@/views/mfh/OneMfhPortfolio.vue'),
        },
        props: {
            default: route => ({ mfhPortfolioId: +route.params.mfhPortfolioId }),
        },
        children: [
            {
                ...CCSRouteMap['one.mfh-portfolio.detail'],
                components: {
                    default: () => import('@/components/mfh/portfolio/MFHPortfolioDetails.vue'),
                },
            },
            {
                ...CCSRouteMap['one.mfh-portfolio.tasks'],
                components: {
                    default: () => import('@/components/tasks/TasksTable.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh-portfolio.timeline'],
                components: {
                    default: () => import('@/components/timeline/EventsTimeline.vue'),
                },
                props: {
                    default: route => ({ ...route.params }),
                },
            },
            {
                ...CCSRouteMap['one.mfh-portfolio.financing.overview'],
                components: {
                    default: () => import('@/components/mfh/portfolio/MfhPortfolioFinancingOverview.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh-portfolio.purchasing.overview'],
                components: {
                    default: () => import('@/components/mfh/portfolio/MfhPortfolioPurchasingOverview.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh-portfolio.selling.overview'],
                components: {
                    default: () => import('@/components/mfh/portfolio/MfhPortfolioSellingOverview.vue'),
                },
                props: true,
            },
        ],
    },
];
