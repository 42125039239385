import { getClient } from '@/api/api-client';
import {
    type ExportMonthlyInvestorShareResult,
    type FindPropertyManagementTasksOverviewResult,
    type IAnnualServiceChargeStatement,
    type IDurableJob,
    type IEstateCredential,
    type IEstateMeter,
    type IEstatesResponse,
    type IFetchPropertyManagementTasksOverview,
    type IFetchRentTenancyOverview,
    type IFetchTransactionRuleOpts,
    type IGenerateRentArrearNoticeParams,
    type IInvestorInfo,
    type IMeterReading,
    type IMonthlyInvestorShare,
    type IMonthlyInvestorShareFilterOptions,
    type IMonthlyRentGuarantee,
    type IPaginatedResponse,
    type IPropertyManagementReqQuery,
    type IRent,
    type IRentBalanceAdjustment,
    type IRentTenancy,
    type IRentTenancyDTO,
    InvestorInfo,
    RentTenancy,
    type StringDate,
} from '@condo/domain';
import type {
    CreateInvestorInfoMutationRequest,
    ExportMonthlySevPaymentMutationRequest,
    ExportMonthlySevPaymentMutationResponse,
    ExportMonthlySevPaymentQueryParams,
    FindInvestorAnnualSettlementsQueryParams,
    FindInvestorInfosQueryParams,
    FindInvestorYearlyPayoutsQueryParams,
    FindMonthlyRentGuaranteesQueryParams,
    FindMonthlyRentGuaranteesQueryResponse,
    GetCpiValuesListQueryResponse,
    GetEstateRentTenanciesQueryParams,
    GetInvestorInfoQueryParams,
    GetMinMaxDateOfMonthlyInvestorShareQueryResponse,
    GetRentTenancyQueryParams,
    InsertableInvestorAnnualSettlementSchema,
    InsertableInvestorYearlyPayoutSchema,
    InvestorAnnualSettlement,
    InvestorYearlyPayout,
    PortalName,
    UpdatableInvestorAnnualSettlementSchema,
    UpdatableInvestorYearlyPayoutSchema,
    UpdateInvestorInfoMutationRequest,
    UpdateInvestorInfoQueryParams,
    UpdateMonthlyInvestorShareMutationRequest,
    UpdateMonthlyInvestorShareMutationResponse,
    UpdateMonthlyInvestorShareOperationsMutationRequest,
} from '@condo/sdk/propx';

export type IFetchRentTenanciesOverviewParams = IFetchRentTenancyOverview['findOpts'] & IFetchRentTenancyOverview['queryOpts'];
export type IFetchPropertyManagementTasksOverviewParams = IFetchPropertyManagementTasksOverview['findOpts'] &
    IFetchPropertyManagementTasksOverview['queryOpts'];

export const findPropertyManagementEstates = async (params?: IPropertyManagementReqQuery): Promise<IEstatesResponse> =>
    getClient('basecamp')
        .get('/estates/property-management', { params })
        .then(response => response.data);

export const fetchEstateMeters = async (estateId: number): Promise<IEstateMeter[]> =>
    getClient('basecamp')
        .get(`/estates/${estateId}/estate-meters`)
        .then(r => r.data.estateMeters);

export const saveEstateMeter = async (meter: Partial<IEstateMeter>): Promise<IEstateMeter> => {
    const { estateId } = meter;
    return getClient('basecamp')
        .post(`/estates/${estateId}/estate-meters`, meter)
        .then(r => r.data.meter);
};

export const saveEstateReading = async (estateMeterId: number, reading: IMeterReading): Promise<IMeterReading> =>
    getClient('basecamp')
        .post(`/estate-meters/${estateMeterId}/readings`, reading)
        .then(r => r.data.meter);

export const deleteMeter = async (estateMeterId: number): Promise<void> => getClient('basecamp').delete(`/estate-meters/${estateMeterId}`);

export const deleteMeterReading = async (estateMeterId: number, readingId: number): Promise<void> =>
    getClient('basecamp')
        .delete(`/estate-meters/${estateMeterId}/readings/${readingId}`)
        .then(r => r.data);

// =================== investor infos ===================

export const saveInvestorInfo = async (
    investorInfoDto: CreateInvestorInfoMutationRequest | UpdateInvestorInfoMutationRequest,
    opts?: UpdateInvestorInfoQueryParams,
): Promise<InvestorInfo> => {
    if (investorInfoDto.investorInfoId) {
        const data = await getClient('propx').updateInvestorInfo({ investorInfoId: investorInfoDto.investorInfoId }, investorInfoDto, opts);
        return new InvestorInfo(data.investorInfo);
    }
    const data = await getClient('propx').createInvestorInfo(investorInfoDto as CreateInvestorInfoMutationRequest);
    return new InvestorInfo(data.investorInfo);
};

export const findInvestorInfos = async (query?: { estateId?: number }, opts?: Omit<FindInvestorInfosQueryParams, 'estateId'>): Promise<InvestorInfo[]> =>
    getClient('propx')
        .findInvestorInfos({ ...query, ...opts })
        .then(({ investorInfos }) => investorInfos.map(iinfo => new InvestorInfo(iinfo as IInvestorInfo)));

export const getInvestorInfo = async (investorInfoId: number, opts?: GetInvestorInfoQueryParams): Promise<InvestorInfo | null> => {
    if (!investorInfoId) {
        return null;
    }
    const data = await getClient('propx').getInvestorInfo({ investorInfoId }, opts);
    return new InvestorInfo(data.investorInfo);
};

// =================== rent tenancy ===================

export const saveTenancy = async (
    rentTenancy: IRentTenancyDTO & IFetchTransactionRuleOpts,
    investorInfo?: Partial<IInvestorInfo>,
    rent?: Partial<IRent>,
): Promise<RentTenancy> => {
    if (rentTenancy.rentTenancyId) {
        const data = await getClient('propx').updateRentTenancy({ rentTenancyId: rentTenancy.rentTenancyId }, rentTenancy);
        return new RentTenancy(data.rentTenancy);
    }
    const data = await getClient('propx').saveRentTenancy({ rentTenancy, investorInfo, rent });
    return new RentTenancy(data.rentTenancy);
};

export const fetchRentTenancy = async (rentTenancyId: number, params?: GetRentTenancyQueryParams): Promise<RentTenancy> => {
    const data = await getClient('propx').getRentTenancy({ rentTenancyId }, params);
    return new RentTenancy(data as IRentTenancy);
};

export const fetchRentTenancyOverview = async (
    params: IFetchRentTenanciesOverviewParams,
): Promise<{
    rentTenancies: RentTenancy[];
    count: number;
}> => {
    const { data } = await getClient('basecamp').get<{ rentTenancies: IRentTenancy[]; count: number }>(`/rent-tenancies`, { params });
    return { rentTenancies: data.rentTenancies.map((rt: IRentTenancy) => new RentTenancy(rt)), count: data.count };
};

export const fetchRentTenanciesOfEstate = async (estateId: number, params: GetEstateRentTenanciesQueryParams): Promise<RentTenancy[]> => {
    const data = await getClient('propx').getEstateRentTenancies({ estateId }, params);
    return (data.rentTenancies.map((rt: IRentTenancy) => new RentTenancy(rt)) as RentTenancy[]).sort((a, b) => b.rentTenancyId - a.rentTenancyId);
};

export const recalculateRentBalance = async (rentTenancyId: number): Promise<RentTenancy> => {
    const data = await getClient('propx').recalculateRentBalance({ rentTenancyId });
    return new RentTenancy(data.rentTenancy as IRentTenancy);
};

export const fetchActiveRentTenanciesOfManyEstate = async (estateIds: number[]): Promise<RentTenancy[]> => {
    const data = await getClient('propx').getManyEstateActiveRentTenancies({ estateIds });
    return data.rentTenancies.map(rt => new RentTenancy(rt as IRentTenancy));
};
// =================== NebenkostenAbrechnung ===================
export const fetchAnnualServiceChargeStatements = async (params: { rentTenancyId?: number; rentTenancyIds?: number[] }): Promise<
    IAnnualServiceChargeStatement[]
> => {
    const { annualServiceChargesStatements } = await getClient('propx').findAnnualServiceChargeStatements(params);
    return annualServiceChargesStatements;
};

export const deleteAnnualServiceCharge = async (serviceChargeStatementId: number): Promise<void> =>
    getClient('propx').deleteAnnualServiceChargeStatement({ serviceChargeStatementId });

export const saveAnnualServiceChargeStatement = async (data: Partial<IAnnualServiceChargeStatement>) =>
    getClient('propx').saveAnnualServiceChargeStatement({ annualServiceChargeStatement: data });

// =================== rent ===================

export const saveRent = async (rent: IRent): Promise<IRent> => {
    if (rent.rentId) {
        const data = await getClient('propx').updateRent({ rentTenancyId: rent.rentTenancyId, rentId: rent.rentId }, rent);
        return data.rent;
    }
    const data = await getClient('propx').createRent({ rentTenancyId: rent.rentTenancyId }, rent);
    return data.rent;
};

export const getCpiValuesList = async (startDate?: Date, endDate?: Date): Promise<GetCpiValuesListQueryResponse> => {
    return getClient('propx').getCpiValuesList({ startDate, endDate });
};

export const updateRentBalanceAdjustments = async (rentId: number, balanceAdjustments: IRentBalanceAdjustment[]): Promise<void> => {
    await getClient('propx').updateRentBalanceAdjustments({ rentId }, { balanceAdjustments });
};

export const getMonthlyInvestorShare = async (
    query?: IMonthlyInvestorShareFilterOptions,
): Promise<IPaginatedResponse<IMonthlyInvestorShare, 'investorShares'>> => {
    return getClient('basecamp')
        .get('/investor-share', { params: query })
        .then(({ data }) => {
            return {
                investorShares: data.investorShares.map(iinfo => {
                    return { ...iinfo, investorInfo: new InvestorInfo(iinfo.investorInfo) };
                }),
                pagination: data.pagination,
            };
        });
};

export const getMonthlyInvestorShareIds = async (
    query?: IMonthlyInvestorShareFilterOptions,
): Promise<
    {
        monthlyInvestorShareId: number;
        estateId?: number;
        condoUuid?: string;
        checked?: boolean;
    }[]
> =>
    getClient('basecamp')
        .get('/investor-share/ids', { params: query })
        .then(({ data }) => {
            return data;
        });

export const fetchMonthlyRentGuarantees = async (params?: FindMonthlyRentGuaranteesQueryParams): Promise<FindMonthlyRentGuaranteesQueryResponse> =>
    getClient('propx').findMonthlyRentGuarantees(params);

export const updateMonthlyRentGuarantee = async (monthlyRentGuaranteeId: number, data: Partial<IMonthlyRentGuarantee>): Promise<IMonthlyRentGuarantee> =>
    getClient('propx')
        .updateMonthlyRentGuarantee({ monthlyRentGuaranteeId }, data)
        .then(({ monthlyRentGuarantee }) => monthlyRentGuarantee as IMonthlyRentGuarantee);

export const getDateRangeOfMonthlyInvestorShare = async (): Promise<GetMinMaxDateOfMonthlyInvestorShareQueryResponse> => {
    return getClient('propx').getMinMaxDateOfMonthlyInvestorShare();
};

export const getMonthlyInvestorSharePaymentFile = async (query?: { month: StringDate }): Promise<ExportMonthlyInvestorShareResult> => {
    const { data } = await getClient('basecamp').get('/investor-share/payment-file', {
        params: query,
    });

    return data;
};

export const getSevPaymentFile = async (
    data: ExportMonthlySevPaymentMutationRequest,
    query: ExportMonthlySevPaymentQueryParams,
): Promise<ExportMonthlySevPaymentMutationResponse> => getClient('propx').exportMonthlySevPayment(data, { month: query?.month });

export const updateMonthlyInvestorShare = async (
    monthlyInvestorShareId: number,
    monthlyInvestorShare?: UpdateMonthlyInvestorShareMutationRequest['data'],
    operationRows?: UpdateMonthlyInvestorShareMutationRequest['operationRows'],
): Promise<UpdateMonthlyInvestorShareMutationResponse['monthlyInvestorShare']> =>
    getClient('propx')
        .updateMonthlyInvestorShare(
            { monthlyInvestorShareId },
            {
                operationRows,
                data: monthlyInvestorShare,
            },
        )
        .then(data => data.monthlyInvestorShare);

export const updateMonthlyInvestorShareOperations = async (monthlyInvestorShareId: number, data: UpdateMonthlyInvestorShareOperationsMutationRequest) =>
    getClient('propx').updateMonthlyInvestorShareOperations({ monthlyInvestorShareId }, data);

export const fetchPropertyManagementTasksOverview = async (
    params: IFetchPropertyManagementTasksOverviewParams,
): Promise<FindPropertyManagementTasksOverviewResult> =>
    getClient('basecamp')
        .get<FindPropertyManagementTasksOverviewResult>(`/property-management-tasks`, { params })
        .then(({ data }) => data);

export const generateRentTenancyReport = async (rentTenancyId: number): Promise<string> =>
    getClient('basecamp')
        .post<{ sheetLink: string }>(`/rent-tenancies/${rentTenancyId}/report/generate`)
        .then(({ data }) => data.sheetLink);

/**
 *
 * @param month in "date" format 'YYYY-MM-DD'
 */
export const generateRentGuarantee = async (month: string): Promise<string> =>
    getClient('propx')
        .rentGuaranteeBatchGenerate({ month: month })
        .then(({ driveFolderId }) => driveFolderId);

export const generateRentArrearNotices = async (rentArrearNotices: IGenerateRentArrearNoticeParams[]): Promise<string> =>
    getClient('propx')
        .generateArrearNotices({ rentArrearNotices })
        .then(({ driveFolderId }) => driveFolderId);

export const deleteRentArrearNotice = async (rentArrearNoticeId: number): Promise<void> => getClient('propx').deleteArrearNotice({ rentArrearNoticeId });

export const sendContactInvitationRequest = async ({
    contactId,
    portalName,
}: {
    contactId: number;
    portalName: PortalName;
}): Promise<void> => {
    await getClient('propx').sendContactInvitation({ contactId }, { portalName });
};

export const fetchEstateCredentials = async (estateId: number): Promise<IEstateCredential[]> =>
    getClient('basecamp')
        .get<{ estateCredentials: IEstateCredential[] }>(`/estates/${estateId}/credentials`)
        .then(({ data }) => data.estateCredentials);

export const deleteEstateCredential = async (estateCredentialId: number): Promise<void> => {
    await getClient('basecamp').delete(`/estates/credentials/${estateCredentialId}`);
};

export const createEstateCredential = async (estateId: number, data: Partial<IEstateCredential>): Promise<IEstateCredential> =>
    getClient('basecamp')
        .post<{
            estateCredential: IEstateCredential;
        }>(`/estates/${estateId}/credentials`, data)
        .then(({ data }) => data.estateCredential);

export const getEntitySetting = async (
    estateId: number,
): Promise<{
    [key: string]: string | boolean;
} | null> => {
    const { entitySetting } = await getClient('propx').getEntitySetting({ estateId });

    return entitySetting?.value ?? null;
};

export const saveFinancialModuleSetting = async (estateId: number, value: boolean): Promise<void> => {
    await getClient('propx').upsertEntitySetting({ estateId, value: { disableFinancialModule: value } });
};

export const restoreTenancyLedger = async (rentTenancyId: number): Promise<void> =>
    getClient('basecamp').put(`/rent-tenancies/${rentTenancyId}/restore-ledger`, {});

export const recoverInvestorLedgerAsync = async (investorInfoId: number): Promise<IDurableJob> =>
    getClient('basecamp').post(`/investor-infos/${investorInfoId}/async-recover-ledger`, {});

export const recoverInvestorLedger = async (investorInfoId: number): Promise<void> =>
    getClient('basecamp').post(`/investor-infos/${investorInfoId}/recover-ledger`, {});

/**
 * InvestorAnnualSettlements
 */
export const fetchInvestorInvestorAnnualSettlements = async (params: FindInvestorAnnualSettlementsQueryParams): Promise<InvestorAnnualSettlement[]> => {
    const { investorAnnualSettlements } = await getClient('propx').findInvestorAnnualSettlements(params);
    return investorAnnualSettlements;
};

export const createInvestorInvestorAnnualSettlement = async (data: InsertableInvestorAnnualSettlementSchema): Promise<InvestorAnnualSettlement> => {
    const { investorAnnualSettlement } = await getClient('propx').createInvestorAnnualSettlement({ investorAnnualSettlement: data });
    return investorAnnualSettlement;
};

export const deleteInvestorInvestorAnnualSettlement = async (investorAnnualSettlementId: number): Promise<void> => {
    await getClient('propx').deleteInvestorAnnualSettlement({ investorAnnualSettlementId });
};

export const updateInvestorInvestorAnnualSettlement = async (data: UpdatableInvestorAnnualSettlementSchema): Promise<InvestorAnnualSettlement> => {
    const { investorAnnualSettlement } = await getClient('propx').updateInvestorAnnualSettlement({ investorAnnualSettlement: data });
    return investorAnnualSettlement;
};

/**
 * InvestorYearlyPayouts
 */
export const fetchInvestorYearlyPayouts = async (params: FindInvestorYearlyPayoutsQueryParams): Promise<InvestorYearlyPayout[]> => {
    const { investorYearlyPayouts } = await getClient('propx').findInvestorYearlyPayouts(params);
    return investorYearlyPayouts;
};

export const createInvestorYearlyPayout = async (data: InsertableInvestorYearlyPayoutSchema): Promise<InvestorYearlyPayout> => {
    const { investorYearlyPayout } = await getClient('propx').createInvestorYearlyPayout({ investorYearlyPayout: data });
    return investorYearlyPayout;
};

export const deleteInvestorYearlyPayout = async (investorYearlyPayoutId: number): Promise<void> => {
    await getClient('propx').deleteInvestorYearlyPayout({ investorYearlyPayoutId });
};

export const updateInvestorYearlyPayout = async (data: UpdatableInvestorYearlyPayoutSchema): Promise<InvestorYearlyPayout> => {
    const { investorYearlyPayout } = await getClient('propx').updateInvestorYearlyPayout({ investorYearlyPayout: data });
    return investorYearlyPayout;
};
