import { fetchUserCompanyFunctions, updateUserCompanyFunction } from '@/api/user.api';
import type { CompanyFunction, IUserCompanyFunction } from '@condo/domain';

export interface IUserCompanyFunctionStore {
    userCompanyFunctions: IUserCompanyFunction[];
    companyFunctionsByUser: Record<string, IUserCompanyFunction>;
    usersByCompanyFunctions: Partial<Record<CompanyFunction, string[]>>;
}

function getInitialState(): IUserCompanyFunctionStore {
    return {
        userCompanyFunctions: [],
        companyFunctionsByUser: {},
        usersByCompanyFunctions: {},
    };
}

const state = getInitialState();

const getters = {
    userCompanyFunctions(state: IUserCompanyFunctionStore): IUserCompanyFunction[] {
        return state.userCompanyFunctions;
    },
    companyFunctionsByUser(state: IUserCompanyFunctionStore): Record<string, IUserCompanyFunction> {
        return state.userCompanyFunctions.reduce((acc, cur) => {
            return {
                ...acc,
                [cur.userId]: cur,
            };
        }, {});
    },
    usersByCompanyFunctions(state: IUserCompanyFunctionStore): Partial<Record<CompanyFunction, string[]>> {
        return state.userCompanyFunctions.reduce((acc, cur) => {
            return {
                ...acc,
                [cur.companyFunction]: [...(acc[cur.companyFunction] ?? []), cur],
            };
        }, {});
    },
};

const actions = {
    async fetchUserCompanyFunctions({ commit }) {
        const userCompanyFunctions = await fetchUserCompanyFunctions();
        commit('setUserCompanyFunctions', userCompanyFunctions);
    },
    async updateUserCompanyFunction(
        { commit },
        payload: {
            userCompanyFunctionId: number | null;
            userId: string;
            contactPersonId: number | undefined;
            companyFunction: CompanyFunction;
        },
    ) {
        await updateUserCompanyFunction(payload);
    },
};

const mutations = {
    setUserCompanyFunctions(state: IUserCompanyFunctionStore, userCompanyFunctions: IUserCompanyFunction[]) {
        state.userCompanyFunctions = userCompanyFunctions;
    },
};

export const userCompanyFunctionStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
